import React from 'react';
import PropTypes from 'prop-types';

const Card = ({className, cardCode, url}) => (
	<svg className={className} width="283px" height="411px" viewBox="0 0 283 411" version="1.1" xmlns="http:/</svg>/www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
		<defs>
			<rect id="path-1" x="0" y="0" width="180" height="300" rx="1" />
			<filter x="-5.3%" y="-2.8%" width="110.6%" height="106.3%" filterUnits="objectBoundingBox" id="filter-2">
				<feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
				<feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
				<feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
				<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0" type="matrix" in="shadowBlurOuter1" />
			</filter>
			<rect id="path-3" x="0" y="0" width="180" height="300" rx="1" />
			<filter x="-5.3%" y="-2.8%" width="110.6%" height="106.3%" filterUnits="objectBoundingBox" id="filter-4">
				<feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
				<feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
				<feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
				<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0" type="matrix" in="shadowBlurOuter1" />
			</filter>
		</defs>
		<g id="UI-KIT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Other-elements" transform="translate(-144.000000, -553.000000)">
				<g id="Group-2" transform="translate(150.000000, 558.000000)">
					<g id="QRCODE" opacity="0.900000036">
						<g id="Rectangle-4-Copy-2">
							<use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1" />
							<use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
						</g>
						<g id="qr-roverpix-com" strokeWidth="1" fillRule="evenodd" transform="translate(3.000000, 90.000000)">
							<rect id="Rectangle-path" fill="#FFFFFF" fillRule="nonzero" x="0" y="0" width="172" height="172" />
							<g id="Group" transform="translate(23.724138, 23.724138)" fill="#000000" fillRule="nonzero">
								<g id="p-link">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 5.931034)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 29.655172)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 88.965517)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 112.689655)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(5.931034, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(5.931034, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(5.931034, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(5.931034, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(5.931034, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(5.931034, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(5.931034, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(29.655172, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(29.655172, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(29.655172, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(29.655172, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(29.655172, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(29.655172, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 5.931034)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 29.655172)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 88.965517)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 112.689655)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(41.517241, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(41.517241, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(41.517241, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(41.517241, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 29.655172)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 77.103448)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 5.931034)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 41.517241)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 5.931034)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 29.655172)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(65.241379, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(65.241379, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(65.241379, 41.517241)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(65.241379, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(65.241379, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(65.241379, 88.965517)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(65.241379, 112.689655)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 112.689655)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 77.103448)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 88.965517)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 112.689655)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 5.931034)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 29.655172)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 77.103448)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 112.689655)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 77.103448)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 88.965517)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 112.689655)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(112.689655, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(112.689655, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(112.689655, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(112.689655, 77.103448)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(112.689655, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 5.931034)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 29.655172)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
							</g>
						</g>
						<g id="barcode" strokeWidth="1" fillRule="evenodd" transform="translate(15.000000, 24.000000)">
							<g id="Group" transform="translate(0.000000, 31.000000)">
								<rect id="Rectangle-path" fill="#FFFFFF" fillRule="nonzero" x="0" y="0" width="150" height="52.4955172" />
								<path d="M1.03448276,0 L1.03448276,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M3.62068966,0 L3.62068966,39.6551724" id="Shape" stroke="#000000" />
								<path d="M8.79310345,0 L8.79310345,39.6551724" id="Shape" stroke="#000000" />
								<path d="M12.4137931,0 L12.4137931,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M17.0689655,0 L17.0689655,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M21.2068966,0 L21.2068966,39.6551724" id="Shape" stroke="#000000" />
								<path d="M23.2758621,0 L23.2758621,39.6551724" id="Shape" stroke="#000000" />
								<path d="M27.4137931,0 L27.4137931,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M32.0689655,0 L32.0689655,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M35.6896552,0 L35.6896552,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M39.8275862,0 L39.8275862,39.6551724" id="Shape" stroke="#000000" />
								<path d="M42.4137931,0 L42.4137931,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M46.5517241,0 L46.5517241,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M50.1724138,0 L50.1724138,39.6551724" id="Shape" stroke="#000000" />
								<path d="M54.3103448,0 L54.3103448,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M57.9310345,0 L57.9310345,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M61.5517241,0 L61.5517241,39.6551724" id="Shape" stroke="#000000" />
								<path d="M64.6551724,0 L64.6551724,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M69.3103448,0 L69.3103448,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M73.9655172,0 L73.9655172,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M78.1034483,0 L78.1034483,39.6551724" id="Shape" stroke="#000000" />
								<path d="M80.1724138,0 L80.1724138,39.6551724" id="Shape" stroke="#000000" />
								<path d="M84.3103448,0 L84.3103448,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M88.9655172,0 L88.9655172,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M92.0689655,0 L92.0689655,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M95.6896552,0 L95.6896552,39.6551724" id="Shape" stroke="#000000" />
								<path d="M99.8275862,0 L99.8275862,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M103.448276,0 L103.448276,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M107.068966,0 L107.068966,39.6551724" id="Shape" stroke="#000000" />
								<path d="M109.615385,0 L109.615385,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M114.827586,0 L114.827586,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M118.448276,0 L118.448276,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M122.586207,0 L122.586207,39.6551724" id="Shape" stroke="#000000" />
								<path d="M126.206897,0 L126.206897,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M130.862069,0 L130.862069,39.6551724" id="Shape" stroke="#000000" />
								<path d="M132.931034,0 L132.931034,39.6551724" id="Shape" stroke="#000000" />
								<path d="M137.586207,0 L137.586207,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M143.275862,0 L143.275862,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M146.37931,0 L146.37931,39.6551724" id="Shape" stroke="#000000" />
								<path d="M148.965517,0 L148.965517,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
							</g>
							<text id="cardCode" fill="#000000" fontFamily="Roboto-Medium, Roboto" fontSize="18" fontWeight="400">
								<tspan x="24.2475586" y="17">
									{cardCode}
								</tspan>
							</text>
						</g>
						<text id="cardUrl" fontFamily="Roboto-Bold, Roboto" fontSize="12" fontWeight="bold" fill="#212121" textAnchor="middle">
							<tspan x="90" y="266">
								{url}
							</tspan>
						</text>
					</g>
					<g id="QRCODE" transform="translate(91.000000, 99.000000)">
						<g id="Rectangle-4-Copy-2">
							<use fill="black" fillOpacity="1" filter="url(#filter-4)" xlinkHref="#path-3" />
							<use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-3" />
						</g>
						<g id="qr-roverpix-com" strokeWidth="1" fillRule="evenodd" transform="translate(3.000000, 90.000000)">
							<rect id="Rectangle-path" fill="#FFFFFF" fillRule="nonzero" x="0" y="0" width="172" height="172" />
							<g id="Group" transform="translate(23.724138, 23.724138)" fill="#000000" fillRule="nonzero">
								<g id="p-link">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 5.931034)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 29.655172)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 88.965517)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 112.689655)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(0.000000, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(5.931034, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(5.931034, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(5.931034, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(5.931034, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(5.931034, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(5.931034, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(5.931034, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(11.862069, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(17.793103, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(23.724138, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(29.655172, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(29.655172, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(29.655172, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(29.655172, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(29.655172, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(29.655172, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 5.931034)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 29.655172)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 88.965517)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 112.689655)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(35.586207, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(41.517241, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(41.517241, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(41.517241, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(41.517241, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 29.655172)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 77.103448)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(47.448276, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 5.931034)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 41.517241)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(53.379310, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 5.931034)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 29.655172)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(59.310345, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(65.241379, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(65.241379, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(65.241379, 41.517241)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(65.241379, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(65.241379, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(65.241379, 88.965517)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(65.241379, 112.689655)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(71.172414, 112.689655)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 77.103448)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 88.965517)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 112.689655)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(77.103448, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 5.931034)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 29.655172)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 77.103448)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(83.034483, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(88.965517, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 112.689655)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(94.896552, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 77.103448)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 88.965517)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 100.827586)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(100.827586, 112.689655)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 53.379310)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 94.896552)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(106.758621, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(112.689655, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(112.689655, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(112.689655, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(112.689655, 77.103448)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(112.689655, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 0.000000)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 5.931034)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 11.862069)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 17.793103)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 23.724138)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 29.655172)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 35.586207)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 47.448276)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 59.310345)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 65.241379)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 71.172414)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 83.034483)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 106.758621)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
								<g id="p-link" transform="translate(118.620690, 118.620690)">
									<rect id="p" x="0" y="0" width="5.93103448" height="5.93103448" />
								</g>
							</g>
						</g>
						<g id="barcode" strokeWidth="1" fillRule="evenodd" transform="translate(15.000000, 24.000000)">
							<g id="Group" transform="translate(0.000000, 31.000000)">
								<rect id="Rectangle-path" fill="#FFFFFF" fillRule="nonzero" x="0" y="0" width="150" height="52.4955172" />
								<path d="M1.03448276,0 L1.03448276,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M3.62068966,0 L3.62068966,39.6551724" id="Shape" stroke="#000000" />
								<path d="M8.79310345,0 L8.79310345,39.6551724" id="Shape" stroke="#000000" />
								<path d="M12.4137931,0 L12.4137931,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M17.0689655,0 L17.0689655,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M21.2068966,0 L21.2068966,39.6551724" id="Shape" stroke="#000000" />
								<path d="M23.2758621,0 L23.2758621,39.6551724" id="Shape" stroke="#000000" />
								<path d="M27.4137931,0 L27.4137931,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M32.0689655,0 L32.0689655,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M35.6896552,0 L35.6896552,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M39.8275862,0 L39.8275862,39.6551724" id="Shape" stroke="#000000" />
								<path d="M42.4137931,0 L42.4137931,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M46.5517241,0 L46.5517241,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M50.1724138,0 L50.1724138,39.6551724" id="Shape" stroke="#000000" />
								<path d="M54.3103448,0 L54.3103448,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M57.9310345,0 L57.9310345,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M61.5517241,0 L61.5517241,39.6551724" id="Shape" stroke="#000000" />
								<path d="M64.6551724,0 L64.6551724,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M69.3103448,0 L69.3103448,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M73.9655172,0 L73.9655172,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M78.1034483,0 L78.1034483,39.6551724" id="Shape" stroke="#000000" />
								<path d="M80.1724138,0 L80.1724138,39.6551724" id="Shape" stroke="#000000" />
								<path d="M84.3103448,0 L84.3103448,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M88.9655172,0 L88.9655172,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M92.0689655,0 L92.0689655,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M95.6896552,0 L95.6896552,39.6551724" id="Shape" stroke="#000000" />
								<path d="M99.8275862,0 L99.8275862,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M103.448276,0 L103.448276,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M107.068966,0 L107.068966,39.6551724" id="Shape" stroke="#000000" />
								<path d="M109.615385,0 L109.615385,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M114.827586,0 L114.827586,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M118.448276,0 L118.448276,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M122.586207,0 L122.586207,39.6551724" id="Shape" stroke="#000000" />
								<path d="M126.206897,0 L126.206897,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M130.862069,0 L130.862069,39.6551724" id="Shape" stroke="#000000" />
								<path d="M132.931034,0 L132.931034,39.6551724" id="Shape" stroke="#000000" />
								<path d="M137.586207,0 L137.586207,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
								<path d="M143.275862,0 L143.275862,39.6551724" id="Shape" stroke="#000000" strokeWidth="3" />
								<path d="M146.37931,0 L146.37931,39.6551724" id="Shape" stroke="#000000" />
								<path d="M148.965517,0 L148.965517,39.6551724" id="Shape" stroke="#000000" strokeWidth="2" />
							</g>
							<text id="cardCode" fill="#000000" fontFamily="Roboto-Medium, Roboto" fontSize="18" fontWeight="400">
								<tspan x="24.2475586" y="17">
									{cardCode}
								</tspan>
							</text>
						</g>
						<text id="cardUrl" fontFamily="Roboto-Bold, Roboto" fontSize="12" fontWeight="bold" fill="#212121" textAnchor="middle">
							<tspan x="90" y="266">
								{url}
							</tspan>
						</text>
					</g>
					<ellipse cx="180" cy="133" rx="70" ry="20" stroke="#E61E21" strokeWidth="2" />
				</g>
			</g>
		</g>
	</svg>
);

Card.propTypes = {
	className: PropTypes.string,
	cardCode: PropTypes.string,
	url: PropTypes.string
};

Card.defaultProps = {
	className: '',
	cardCode: 'FO-RTFI-089',
	url: 'roverpix.com'
};

export default Card;
