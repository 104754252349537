import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Form from '../../Atoms/Form';
import Button from '../../Atoms/Button';
import CardInput from '../../Atoms/CardInput';
import Input2FA from '../../Atoms/Input2FA';

import './index.sass';

const CardForm = ({ className, loading, hasError, cardCode, buttonMessage, children, onChange, onSubmit, show2FA, secret, onChangeSecret}) => {
	const cardInput = useRef(null);

	return (
		<Form className={`CardForm ${className}`} onSubmit={onSubmit}>
			<CardInput
				className="Home__input"
				ref={cardInput}
				disabled={loading}
				value={cardCode}
				onChange={onChange}
			/>
			{show2FA && <Input2FA value={secret} onChange={onChangeSecret}/>}
			<Button
				className="Home__button"
				color={hasError ? 'error' : 'primary'}
				onClick={onSubmit}
				loading={loading}
			>
				{buttonMessage}
			</Button>
			{children}
		</Form>
	)
}

CardForm.propTypes = {
	className: PropTypes.string,
	cardCode: PropTypes.string,
	buttonMessage: PropTypes.string,
	loading: PropTypes.bool,
	hasError: PropTypes.bool,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	secret: PropTypes.string,
	onChangeSecret: PropTypes.func,
};

CardForm.defaultProps = {
	className: '',
	cardCode: '',
	buttonMessage: '',
	secret: '',
	loading: false,
	hasError: false,
	onChange: () => {},
	onSubmit: () => {},
	onChangeSecret: () => {},
};

export default CardForm;
