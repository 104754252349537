import React, { useState, useRef, useCallback, useEffect } from "react";
import PropTypes from 'prop-types';
import Webcam from "react-webcam";

import Button from "../Button";
import ToolTip from "../ToolTip";
import Text from "../Text";
import { ReactComponent as FaceFrame } from "../../../assets/icons/faceFrame.svg";
import { ReactComponent as WhiteCamera} from "../../../assets/icons/white-camera.svg";

import "./index.sass";

const Camera = ({ className, onSelectedImage, error, handleTootilpClose, onSearching, mirrored, facingMode, errorTooltipText, searchButtonText, onSearchImage, countdownText }) => {
	const webcamRef = useRef(null);
	const [img, setImg] = useState(null);
	const [countdown, setCountdown] = useState(3);

	const onCapture = useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot();
		setImg(imageSrc);
		onSelectedImage(imageSrc);
	}, [webcamRef, onSelectedImage]);

	const resetCountdown = () => {
		setCountdown(3);
	}

	useEffect(() => {
		if (img !== null) {
			const timer = setInterval(() => {
				setCountdown(prevCountdown => prevCountdown - 1);
			}, 1000);

			setTimeout(() => {
				setImg(null);
				clearInterval(timer);
				resetCountdown();
			}, 3000);
		}
	}, [img]);

	const videoConstraints = {
		facingMode: facingMode === 'environment' ? { exact: "environment" } : "user",
	};

	const onSearchImages = () => {
		onSearchImage(img)
	}

	return (
		<div className={`Camera ${className}`}>
			{img ? (
				<Text className="Camera__CountdownText" variant="caption">
				{countdownText} {countdown} {countdown !== 0 ? 's' : ''}
				</Text>
			) : null}
			{img === null  ? (
				<div className="Camera__Container" >
					<div className="Camera__Container__Wrapper">
						<FaceFrame className="Camera__Container__Wrapper__Overlay" 
						/>
						<Webcam
							className="Camera__Container__Wrapper__Webcam"
							screenshotFormat="image/jpeg"
							videoConstraints={videoConstraints}
							audio={false}
							ref={webcamRef}
							mirrored={mirrored}
						/>
					</div>
				</div>
			) : (
				<div className="Camera__ImageContainer">
					<img className="Camera__ImageContainer__Image"
						src={img} alt="screenshot"/>
				</div>
			)}
			{ error && (
				<ToolTip 
					className="Camera__ToolTip"
					onClick={handleTootilpClose}
					text={errorTooltipText}
				/>
			)}

			<div className="Camera__Actions" >
				{img === null ? (
					<Button 
						className="Camera__Actions__Capture" 
						color="secondary" 
						onClick={onCapture}
					> 
						<WhiteCamera /> 
					</Button>
					) : null
				}
				<Button className="Camera__Actions__SearchButton" onClick={onSearchImages} loading={onSearching}>
					{searchButtonText}
				</Button>
			</div>
		</div>
	);
}

Camera.propTypes = {
	className: PropTypes.string,
	onSelectedImage: PropTypes.func,
	error: PropTypes.bool,
	handleTootilpClose: PropTypes.func,
	onSearching: PropTypes.bool,
	mirrored: PropTypes.bool,
	facingMode: PropTypes.oneOf(['user', 'environment']),
	errorTooltipText: PropTypes.string,
	searchButtonText: PropTypes.string,
	onSearchImage: PropTypes.func.isRequired,
};

Camera.defaultProps = {
	className: '',
	onSelectedImage: () => { },
	error: false,
	handleTootilpClose: () => { },
	onSearching: false,
	mirrored: true,
	facingMode: 'user',
	errorTooltipText: '',
	searchButtonText: '',
};
export default Camera;
