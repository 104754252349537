import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import Badge from "@material-ui/core/Badge";
import {
	CreditCard as CreditCardIcon,
	AddPhotoAlternateOutlined,
} from "@material-ui/icons";

import "./index.sass";

import {
	addCard,
	removeCard,
	fetchMedias,
	clearMedias,
	clearOrder,
	clearCard,
	clearPayment,
} from "../../../store/actions";
import { Analytics, LocalStorage } from "../../../shared";

import Arrow from "../../Atoms/Arrow";
import Logo from "../../Atoms/Logo";
import ImgSvg from "../../Atoms/ImgSvg";
import Text from "../../Atoms/Text";
import Box from "../../Molecules/Box";
import Grid from "../../Molecules/Grid";
import Hidden from "../../Molecules/Hidden";
import ProductModal from "../ProductModal";
import AddCardModal from "../AddCardModal";
import AddPhotoModal from "../AddPhotoModal";
import IconButton from "../../Atoms/IconButton";
import LanguageDropdown from "../../Molecules/LanguageDropdown";

class GalleryHeader extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			helpModalOpened: false,
			addCardModalOpened: false,
			addPhotoModalOpened: false,
		};

		this.onBack = this.onBack.bind(this);
		this.openHelpModal = this.openHelpModal.bind(this);
		this.openAddCardModal = this.openAddCardModal.bind(this);
		this.hasCheckedMediasCard = false;
	}

	componentDidUpdate() {
		this.checkOrderMediasCard();
	}

	componentWillUnmount() {
		const { clearMedias, clearOrder, clearCard, clearPayment } = this.props;
		clearMedias();
		clearOrder();
		clearCard();
		clearPayment();
	}

	onBack() {
		const { organization } = this.props;
		const { name: orgName } = organization;

		this.props.history.goBack();
		Analytics.sendEvent("leave_gallery", "browsing", orgName);
	}

	openHelpModal() {
		const { organization } = this.props;
		const { name: orgName } = organization;

		this.setState({
			helpModalOpened: true,
		});
		Analytics.sendEvent("show_help_modal", "browsing", orgName);
	}

	openAddCardModal() {
		this.setState({
			addCardModalOpened: true,
		});
	}

	openAddPhotoModal = () => {
		this.setState({
			addPhotoModalOpened: true,
		});
	};

	handleSuccessAddingPhoto = () => {
		const {
			cardCode,
			fetchMedias,
			organization: organizationState,
		} = this.props;
		const { organization = {} } = organizationState || {};
		const { id } = organization || {};
		this.setState({ addPhotoModalOpened: false });
		fetchMedias(cardCode, id);
	};

	handleCloseAddCardModal = () => {
		this.setState({ addCardModalOpened: false });
		const {
			cardCode,
			fetchMedias,
			organization: organizationState,
		} = this.props;
		const { organization = {} } = organizationState || {};
		const { id } = organization || {};
		fetchMedias(cardCode, id);
	};

	checkOrderMediasCard() {
		const { order: orderState, media } = this.props;
		const { order } = orderState;
		if (!this.hasCheckedMediasCard && order.cards) {
			let { soldMedias, notSoldMedias } = media;
			const medias = [...(soldMedias || []), ...(notSoldMedias || [])];
			const cards = [...new Set(medias.map((media) => media.card))];
			cards.map((card) => this.checkAddCardMediaToOrder(order, card));
			this.hasCheckedMediasCard = true;
		}
	}

	checkAddCardMediaToOrder(order, card) {
		const { addCard } = this.props;
		if (!order.cards.map((card) => card.card).includes(card)) {
			addCard(order.id, card);
		}
	}

	render() {
		const {
			organization,
			intl,
			order: orderState,
			addCard,
			removeCard,
			canBuy,
			media,
			cardCode,
		} = this.props;
		const { name, web_name, features = {} } = organization;
		const { site = {} } = features || {};
		const { can_send_photo: canSendPhoto } = site || {};
		const { order = {}, error: orderError } = orderState;
		const { cards = [] } = order || {};
		const { soldMedias = [], error, loading } = media;
		const noPhotos = !canBuy && (!soldMedias || soldMedias.length === 0);
		const { formatMessage } = intl;
		const messages = defineMessages({
			title: {
				id: "gallery.header.title",
				defaultMessage: "Suas fotos estão prontas",
			},
			help: {
				id: "gallery.header.help",
				defaultMessage: "Valores",
			},
		});

		const handleChangeLanguage = (value) => {
			const currentValue = LocalStorage.getItem("locale");

			if (currentValue !== value) {
				Analytics.sendEvent(`change_language_to_${value}`, "browsing");
				LocalStorage.setItem("locale", value);
				document.location.reload(true);
			}
			this.cardInput.input.focus();
		};

		return (
			<div className="GalleryHeader">
				<Box className="GalleryHeader__wrapper" borderPosition="none">
					<Grid className="GalleryHeader__container" type="container">
						<Grid
							className="GalleryHeader__item"
							xs={6}
							sm={6}
							md={4}
							lg={4}
							xl={4}
						>
							<div className="GalleryHeader__item__actions">
								<Arrow
									className="GalleryHeader__button"
									direction="left"
									onClick={this.onBack}
								/>
								{canBuy && (
									<Badge
										badgeContent={cards.length}
										color="primary"
										className="GalleryHeader__item__actions__button GalleryHeader__item__actions__button--badge"
										onClick={this.openAddCardModal}
									>
										<CreditCardIcon
											className="GalleryHeader__item__actions__button GalleryHeader__item__actions__button--icon"
											onClick={this.openAddCardModal}
										/>
									</Badge>
								)}
								{canSendPhoto && (
									<IconButton
										className="GalleryHeader__item__actions__button GalleryHeader__item__actions__button--badge"
										onClick={this.openAddPhotoModal}
									>
										<AddPhotoAlternateOutlined className="GalleryHeader__item__actions__button GalleryHeader__item__actions__button--icon" />
									</IconButton>
								)}
							</div>
						</Grid>
						<Hidden smDown>
							<Grid
								className="GalleryHeader__item"
								xs={1}
								sm={1}
								md={4}
								lg={4}
								xl={4}
							>
								<div className="GalleryHeader__title__wrapper">
									{!error && !loading && !noPhotos && (
										<Text className="GalleryHeader__title" variant="h5">
											{formatMessage(messages.title)}
										</Text>
									)}
								</div>
							</Grid>
						</Hidden>
						<Grid
							className="GalleryHeader__item item--actions"
							xs={6}
							sm={6}
							md={4}
							lg={4}
							xl={4}
						>
							{canBuy && (
								<div
									className="GalleryHeader__item__help"
									onClick={this.openHelpModal}
								>
									<Text className="GalleryHeader__helpText">
										{formatMessage(messages.help)}
									</Text>
									<ImgSvg name="values" className="item--actions__openModal" />
								</div>
							)}
							{canBuy ? (
								<Hidden smDown>
									<Logo className="GalleryHeader__logo" name={web_name} />
								</Hidden>
							) : (
								<Logo className="GalleryHeader__logo" name={web_name} />
							)}
							<LanguageDropdown
								className="GalleryHeader__languages"
								onChange={handleChangeLanguage}
							/>
						</Grid>
					</Grid>
				</Box>
				<ProductModal
					isOpened={this.state.helpModalOpened}
					onClose={() => this.setState({ helpModalOpened: false })}
				/>
				<AddCardModal
					isOpened={this.state.addCardModalOpened}
					onClose={this.handleCloseAddCardModal}
					onAddCard={(cardCode) => addCard(order.id, cardCode)}
					onRemoveCard={(cardCode) => removeCard(order.id, cardCode)}
					mainCard={cardCode}
					cards={cards}
					organizationName={name}
					invalidCard={orderError === "CARD_NOT_FOUND"}
				/>
				<AddPhotoModal
					isOpened={this.state.addPhotoModalOpened}
					cardCode={cardCode}
					onClose={() => this.setState({ addPhotoModalOpened: false })}
					onSuccess={this.handleSuccessAddingPhoto}
				/>
			</div>
		);
	}
}

GalleryHeader.propTypes = {
	organization: PropTypes.shape({
		name: PropTypes.string,
		web_name: PropTypes.string,
		url: PropTypes.string,
		contact_email: PropTypes.string,
	}).isRequired,
	order: PropTypes.shape({
		cards: PropTypes.arrayOf(
			PropTypes.shape({
				card: PropTypes.string,
			})
		),
	}),
	canBuy: PropTypes.bool,
	cardCode: PropTypes.string,
	intl: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

GalleryHeader.defaultProps = {
	canBuy: false,
	cardCode: "",
};

const mapStateToProps = (state) => ({
	organization: state.organization,
	order: state.order,
	media: state.media,
});

const mapActionsToProps = (dispatch) => ({
	addCard: (orderId, cardCode) => dispatch(addCard(orderId, cardCode)),
	removeCard: (orderId, cardCode) => dispatch(removeCard(orderId, cardCode)),
	fetchMedias: (cardCode,  organizationId) => dispatch(fetchMedias(cardCode,organizationId)),
	clearMedias: () => dispatch(clearMedias()),
	clearOrder: () => dispatch(clearOrder()),
	clearCard: () => dispatch(clearCard()),
	clearPayment: () => dispatch(clearPayment())
});

export default withRouter(
	connect(mapStateToProps, mapActionsToProps)(injectIntl(GalleryHeader))
);
