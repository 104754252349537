import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Modal from "../../Atoms/Modal";
import FacialModalCardSelection from "./Components/FacialModalCardSelection";
import FacialModalCamera from "./Components/FacialModalCamera";
import FacialModalCalendar from "./Components/FacialModalCalendar";
import FacialModalSettings from "./Components/FacialModalSettings";

import "./index.sass";

const DataURIToBlob = (dataURI) => {
	const splitDataURI = dataURI.split(",");
	const byteString =
		splitDataURI[0].indexOf("base64") >= 0
			? atob(splitDataURI[1])
			: decodeURI(splitDataURI[1]);
	const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

	return new Blob([ia], { type: mimeString });
};

const imagesToBlob = (images) => {
	return images.map((image) => DataURIToBlob(image));
};

const FacialModal = ({ className, open, onClose, organizationId, onCardsSelected }) => {
	const [modalState, setModalState] = useState("calendar");
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [date, setDate] = useState();
	const [showWarnings, setShowWarnings] = useState(false);
	const [searchResult, setSearchResult] = useState({});
	const [searchError, setSearchError] = useState(false);
	const [searchingLoader, setSearchingLoader] = useState(false);
	const [mirrorCamera, setMirrorCamera] = useState(true);
	const [checkedChangeCamera, setCheckedChangeCamera] = useState(false);
	const [facingMode, setFacingMode] = useState("user");
	const [cardsList, setCardsList] = useState([]);
	const [researchError, setResearchError] = useState(false);
	const [selectedImages, setSelectedImages] = useState([]);

	const handleSetModalState = () => {
		if (modalState === 'calendar'){
			setDate()
		}
	}

	const handleCardsResult = () => {
		const hasKeys = Object.keys(searchResult).length > 0
		if (!hasKeys){
			return
		}
		if ('cards' in searchResult && searchResult.cards.length > 0) {
			updateCardsList(searchResult.cards);
			setModalState("selectPhotos");
		} else if ('error' in searchResult || !('cards' in searchResult) || searchResult.cards.length === 0){
			setSearchError(true);
			if (cardsList.length > 0) {
				setResearchError(true);
				setTimeout(() => {
					setModalState("selectPhotos");
				}, 5000);
			}
		}
	};

	useEffect(handleCardsResult, [searchResult]);
	useEffect(handleSetModalState, [modalState]);

	if (!open) return null;
	const imageSelected = (img) => {
		setSelectedImages(prevImages => [...prevImages, img]);
	};

	const handleSearchImages = async (selectedImages) => {
		setSearchingLoader(true);
		await getCards(selectedImages);
		setSearchingLoader(false); 
	};

	const getCards = async () => {
		try {
			if (date.length === 0){
				throw new Error('Parameter date was not provided!')
			}
			const allImages = imagesToBlob(selectedImages);
			const formdata = new FormData();
			allImages.forEach((image, index) => {
				formdata.append(`photo`, image, `photo${index}.jpg`);
			});
			const requestOptions = {
				method: "POST",
				body: formdata,
			};

			const fromDate = date[0].toISOString();
			const toDate = date[1]? date[1].toISOString(): addDays(date[0], 1);
			const url = `${process.env.ROVERPIX_API}/api/v1/card/search/photo?organization_id=${organizationId}&from_date=${fromDate}&to_date=${toDate}`;
			const response = await fetch(url, requestOptions).then(res => res.json());
			setSearchResult(response);
		} catch(error) {
			setSearchResult({"error": error})
		}
	};

	const addDays = (date, days) => {
		return (new Date(date.getFullYear(),date.getMonth(),date.getDate()+days, date.getHours(), date.getMinutes(), date.getSeconds())).toISOString()
	}

	const acceptTerms = () => {
		setTermsAccepted(!termsAccepted);
		setShowWarnings(false);
	};

	const handleSearchDates = () => {
		if (termsAccepted && date !== undefined) {
			setModalState("camera");
		} else {
			setShowWarnings(true);
		}
	};

	const updateCardsList = (newCards) => {
		if (cardsList.length === 0) {
			setCardsList(newCards);
		} else {
			const updatedCardsList = [...cardsList];
			newCards.forEach(newCard => {
				if (!cardsList.some(prevCard => prevCard.id === newCard.id)) {
					updatedCardsList.unshift(newCard);
				}
			});
			setCardsList(updatedCardsList);
		}
	};

	const handleMirrorCamera = () => {
		setMirrorCamera(prevState => !prevState);
	};

	const handleCameraChange = () => {
		setFacingMode(prevMode => (prevMode === "user" ? "environment" : "user"));
		setCheckedChangeCamera(prevState => !prevState);
	};

	const navigateToCalendar = () => {
		setModalState("calendar");
		setSearchError(false);
	};

	const navigateToCamera = () => {
		setModalState("camera");
		setSearchError(false);
	};

	return (
		<Modal
			className={`FacialModal ${className}`}
			isOpened={open}
			onClose={onClose}
		>
			{modalState === "calendar" ? (
				<FacialModalCalendar
					value={date}
					onChange={setDate}
					handleSearchDates={handleSearchDates}
					termsAccepted={termsAccepted}
					acceptTerms={acceptTerms}
					showWarnings={showWarnings}
				/>
			) : modalState === "camera" ? (
				<FacialModalCamera
					imageSelected={imageSelected}
					returnButton={navigateToCalendar}
					error={searchError}
					handleTootilpClose={() => setSearchError(false)}
					retakePicture={() => setSearchError(false)}
					onSearching={searchingLoader}
					disableButton={searchingLoader}
					onClickSettings={() => setModalState("cameraSettings")}
					mirrorCamera={mirrorCamera}
					chooseCamera={facingMode}
					research={researchError}
					onSearchImage={handleSearchImages}
					counter={selectedImages.length}
					showCounter={selectedImages.length > 0}
				/>
			) : modalState === "selectPhotos" ? (
				<FacialModalCardSelection
					cardList={cardsList}
					returnButton={navigateToCamera}
					selectedCards={onCardsSelected}
					onSubmitMoreFaces={navigateToCalendar}
				/>
			) : modalState === "cameraSettings" ? (
				<FacialModalSettings 
					onMirrorSwitch={handleMirrorCamera}
					checkMirrorSwitch={mirrorCamera}
					onChangeCamera={handleCameraChange}
					checkChangeCamera={checkedChangeCamera}
					returnButton={navigateToCamera}
				/>
			) : null}
		</Modal>
	);
};

FacialModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	organizationId: PropTypes.string.isRequired,
	onCardsSelected: PropTypes.func.isRequired,
};

FacialModal.defaultProps = {
	className: "",
	open: false,
	onClose: () => {},
};

export default FacialModal;
