import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

class Video extends React.Component {
	state = {
		loading: true,
		cache: null
	}

	componentDidUpdate(prevProps) {
		const { id: lastId, avoidCache: lastAvoidCache } = prevProps
		const { id, avoidCache } = this.props
		const { loading } = this.state

		if (lastId && lastId !== id && !loading) {
			this.setState({
				loading: true
			})
		}

		if (!lastAvoidCache && avoidCache) {
			this.setState({
				cache: new Date().getTime()
			})
		}
	}

	render() {
		const { className, id, filename, alt, type, hasBorder, avoidCache } = this.props;
		const { cache } = this.state;
		let videoSource = `${process.env.ROVERPIX_API}/api/v1/video/download/${id}/${filename}?size=${type}`;

		if (avoidCache) videoSource = `${videoSource}&cache=${cache}`

		return (
			<video
				className={`Video Video--${type} ${className} ${hasBorder ? 'Video--hasBorder' : ''}`}
				src={videoSource}
				alt={alt}
				controls
				controlsList="nodownload"
			/>
		)
	}
}

Video.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string.isRequired,
	filename: PropTypes.string.isRequired,
	alt: PropTypes.string,
	type: PropTypes.oneOf([
		'thumb', 'preview'
	]),
	hasBorder: PropTypes.bool,
	avoidCache: PropTypes.bool,
	isExtra: PropTypes.bool
};

Video.defaultProps = {
	className: '',
	alt: 'Customer video',
	type: 'thumb',
	hasBorder: false,
	avoidCache: false,
	isExtra: false
};

export default Video;
