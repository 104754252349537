import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const Background = ({className, name}) => {
	const gradient = 'linear-gradient(to right, rgba(99, 147, 147, .6), rgba(30, 62, 96, .6))';
	const bkgImage = `url("https://s3.amazonaws.com/roverpix-backgrounds/${name.toLowerCase()}.jpg")`;

	return (
		<div
			className={`Background ${className}`}
			style={name
				? {backgroundImage: `${gradient}, ${bkgImage}`}
				: {backgroundImage: gradient}
			}
		/>
	);
};

Background.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string
};

Background.defaultProps = {
	className: '',
	name: ''
};

export default Background;
