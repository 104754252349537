import React, { useState } from "react";
import PropTypes from "prop-types";

import "./index.sass";
import TranslateIcon from "../../../assets/icons/translate.svg";

const LanguageDropdown = ({ className, onChange }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const languageOptions = [
		{ name: "pt", value: "pt" },
		{ name: "en", value: "en" },
		{ name: "es", value: "es" },
	];

	return (
		<div className={`LanguageDropdown ${className}`}>
			<div className="LanguageDropdown__selected" onClick={toggleDropdown}>
				<>
					<img src={TranslateIcon} alt=""/>
					{!isOpen ? " ▸" : "◂"}
				</>
			</div>
			{isOpen && (
				<div
					className="LanguageDropdown__options"
				>
					{languageOptions.map((option) => (
						<button value={option.value} onClick={() => onChange(option.value)}>
							{option.name}
						</button>
					))}
				</div>
			)}
		</div>
	);
};

LanguageDropdown.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
};

LanguageDropdown.defaultProps = {
	className: "",
	onChange: () => {},
};

export default LanguageDropdown;
