import {
	GET_PAYMENT_BEGIN,
	GET_PAYMENT_SUCCESS,
	GET_PAYMENT_FAILURE,
	DO_PAYMENT_CHECKOUT_BEGIN,
	DO_PAYMENT_CHECKOUT_SUCCESS,
	DO_PAYMENT_PIX_CHECKOUT_SUCCESS,
	DO_PAYMENT_CHECKOUT_FAILURE,
	CLEAR_PAYMENT,
	CHANGE_PAYMENT_METHOD
} from '../actions';

const initialState = {
	status: '',
	loading: false,
	error: '',
	pixText: '',
	method: ''
};

export default function paymentReducer(state = initialState, action) {
	switch (action.type) {
	case GET_PAYMENT_BEGIN:
	case DO_PAYMENT_CHECKOUT_BEGIN:
		return {
			...state,
			loading: true,
			status: '',
			error: ''
		};

	case GET_PAYMENT_SUCCESS:
		return {
			...state,
			loading: false,
			status: action.payload.status,
			pixText: action.payload.pix_text,
			error: ''
		};

	case DO_PAYMENT_CHECKOUT_SUCCESS:
		return {
			...state,
			loading: false,
			status: action.payload,
			error: ''
		};

	case DO_PAYMENT_PIX_CHECKOUT_SUCCESS:
		return {
			...state,
			loading: false,
			status: action.payload.status,
			pixText: action.payload.pixText,
			error: ''
		};

	case GET_PAYMENT_FAILURE:
	case DO_PAYMENT_CHECKOUT_FAILURE:
		return {
			...state,
			loading: false,
			status: '',
			error: action.payload
		};

	case CHANGE_PAYMENT_METHOD:
		return {
			...state,
			method: action.payload
		};

	case CLEAR_PAYMENT:
		return {...initialState}

	default:
		return state;
	}
}
