import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import Text from '../../Atoms/Text'
import './index.sass';

const CartPrice = ({ className, totalPhoto, totalPrice, intl }) => {
	const { formatMessage } = intl;
	const messages = defineMessages({
		totalPhoto: {
			id: 'cartPrice.totalPhoto',
			defaultMessage: 'Total de fotos'
		}
	});
	return (
		<div className={`CartPrice ${className}`}>
			<div className="CartPrice__items">
				<Text className="CartPrice__items__text">
					{formatMessage(messages.totalPhoto)}
				</Text>
				<Text className="CartPrice__items__text CartPrice__items__text__value">
					{totalPhoto}
				</Text>
			</div>
			<div className="CartPrice__separator"></div>
			<div className="CartPrice__items CartPrice__price">
				<Text className="CartPrice__items__text --white --total">
					TOTAL
				</Text>
				<Text className="CartPrice__items__text CartPrice__items__text__value --white">
					R$ {totalPrice}
				</Text>
			</div>
		</div>
	);
};

CartPrice.propTypes = {
	className: PropTypes.string,
	totalPhoto: PropTypes.number,
	totalPrice: PropTypes.number,
	intl: PropTypes.object.isRequired,
};

CartPrice.defaultProps = {
	className: '',
	totalPhoto: 0,
	totalPrice: 0
};

export default injectIntl(CartPrice);
