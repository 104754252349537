import React, { useEffect } from 'react';
import { injectIntl, defineMessages } from "react-intl";
import PropTypes from 'prop-types';

const ZammadChat = ({ className, error, intl }) => {
	const { formatMessage } = intl;
		const messages = defineMessages({
			title: {
				id: "chat.title",
				defaultMessage: "Fale com a gente",
			},
		});

	useEffect(() => {

		const script = document.createElement('script');
		script.src = 'https://zammad.roverpix.com/assets/chat/chat-no-jquery.min.js'; // Ensure this URL is correct
		script.async = true;
		document.body.appendChild(script);
		script.onload = () => {
			if (window.ZammadChat) {
				console.log("ZammadChat is defined, initializing...");

				try {
					new window.ZammadChat({
						fontSize: '12px',
						chatId: 1,
						title: formatMessage(messages.title),
						show: true,
						host: "wss://zammad.roverpix.com/ws",
						cssUrl: "",
						cssAutoLoad: false
					});
					console.log("ZammadChat initialized successfully.");
				} catch (error) {
					console.error("Error initializing ZammadChat:", error);
				}
			} else {
				console.error("ZammadChat is not defined. Check if the script loaded correctly.");
			}
		};

		script.onerror = (event) => {
			console.error("Failed to load Zammad chat script:", event);
			console.log("whatthefuclk")
		};

		return () => {
			console.log("Cleaning up: removing Zammad chat script from the document.");
			document.body.removeChild(script);
		};
	}, []);

	const handleChatOpen = () => {
		console.log("Chat button clicked. Attempting to open chat...");

		if (window.ZammadChat) {
			window.ZammadChat.open();
			console.log("Zammad chat opened successfully.");
		} else {
			console.error("ZammadChat is not available. Cannot open chat.");
		}
	};

	return (
		<button className={`ZammadChatButton open-zammad-chat ${className}`} onClick={handleChatOpen}>
			{formatMessage(messages.title)}
		</button>
	);
};

ZammadChat.propTypes = {
	className: PropTypes.string,
	error: PropTypes.bool,
	intl: PropTypes.object.isRequired,
};

ZammadChat.defaultProps = {
	className: '',
	error: false
};

export default injectIntl(ZammadChat);
