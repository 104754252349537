import {
	FETCH_CARD_BEGIN,
	FETCH_CARD_SUCCESS,
	FETCH_CARD_FAILURE,
	CLEAR_CARD
} from '../actions';

const initialState = {
	card: {},
	mediasCount: 0,
	loading: false,
	error: ''
};

export default function cardReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_CARD_BEGIN:
		return {
			...state,
			loading: true,
			error: ''
		};

	case FETCH_CARD_SUCCESS:
		return {
			...state,
			loading: false,
			card: action.payload.card,
			mediasCount: action.payload.mediasCount
		};

	case FETCH_CARD_FAILURE:
		return {
			...state,
			loading: false,
			error: action.payload
		};

	case CLEAR_CARD:
		return {...initialState}

	default:
		return state;
	}
}
