import React from 'react';
import PropTypes from 'prop-types';
import {default as MaterialHidden} from '@material-ui/core/Hidden';

const Box = ({
	xlDown, xlUp, lgDown, lgUp, mdDown, mdUp, smDown, smUp, xsDown, xsUp, children
}) => (
	<MaterialHidden
		xlDown={xlDown}
		xlUp={xlUp}
		lgDown={lgDown}
		lgUp={lgUp}
		mdDown={mdDown}
		mdUp={mdUp}
		smDown={smDown}
		smUp={smUp}
		xsDown={xsDown}
		xsUp={xsUp}
	>
		{children}
	</MaterialHidden>
);

Box.propTypes = {
	xlDown: PropTypes.bool,
	xlUp: PropTypes.bool,
	lgDown: PropTypes.bool,
	lgUp: PropTypes.bool,
	mdDown: PropTypes.bool,
	mdUp: PropTypes.bool,
	smDown: PropTypes.bool,
	smUp: PropTypes.bool,
	xsDown: PropTypes.bool,
	xsUp: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};

Box.defaultProps = {
	xlDown: false,
	xlUp: false,
	lgDown: false,
	lgUp: false,
	mdDown: false,
	mdUp: false,
	smDown: false,
	smUp: false,
	xsDown: false,
	xsUp: false,
	children: null
};

export default Box;
