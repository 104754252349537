import React from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";

import Calendar from "../../../../Atoms/Calendar";
import Button from "../../../../Atoms/Button";
import Text from "../../../../Atoms/Text";
import CheckBox from "../../../../Atoms/CheckBox";
import Link from "../../../../Atoms/Link";

import "./index.sass";

const FacialModalCalendar = ({ className, intl, onChange, value, handleSearchDates, termsAccepted, acceptTerms, showWarnings }) => {
	const { formatMessage } = intl;
	const messages = defineMessages({
		dateSelection: {
			id: "facialModal.dateSelection",
			defaultMessage: "Selecione o período de busca da foto:",
		},
		minimumDate: {
			id: "facialModal.minimumDate",
			defaultMessage: "(Selecione no mínimo 2 dias)",
		},
		acceptThe: {
			id: "facialModal.acceptThe",
			defaultMessage: "Aceito os",
		},
		termsOfService: {
			id: "facialModal.termsOfService",
			defaultMessage: "termos de serviço",
		},
		search: {
			id: "facialModal.search",
			defaultMessage: "Buscar",
		},
		termsWarning: {
			id: "facialModal.termsWarning",
			defaultMessage: "É necessário aceitar os termos e selecionar um ou mais dias para prosseguir.",
		},
	});

	return (
		<div className={`FacialModalCalendar ${className}`}>
			<Text className="FacialModalCalendar__Text">
				{formatMessage(messages.dateSelection)}
			</Text>
			<Text className="FacialModalCalendar__Text__Subtitle" variant="caption">
				{formatMessage(messages.minimumDate)}
			</Text>
			<Calendar
				className="FacialModalCalendar__Calendar"
				onChange={onChange}
				value={value}
			/>
			<div className="FacialModalCalendar__Terms">
				{showWarnings && (
					<Text className="FacialModalCalendar__Terms__Warning" variant="caption">
						{formatMessage(messages.termsWarning)}
					</Text>
				)}
				<CheckBox
					label={
						<Text variant="caption">
							{formatMessage(messages.acceptThe)}
							<Link href="/terms" target="_blank">
								{formatMessage(messages.termsOfService)}
							</Link>
						</Text>
					}
					required
					checked={termsAccepted}
					onChange={acceptTerms}
				/>
				</div>
				<Button
					className="FacialModalCalendar__Terms__Button"
					onClick={handleSearchDates}
				>
					{formatMessage(messages.search)}
				</Button>
		</div>
	);
};

FacialModalCalendar.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	handleSearchDates: PropTypes.func,
	termsAccepted: PropTypes.bool,
	acceptTerms: PropTypes.func,
	showWarnings: PropTypes.bool,
};

FacialModalCalendar.defaultProps = {
	className: "",
	onChange: () => {},
	handleSearchDates: () => {},
	termsAccepted: false,
	acceptTerms: () => {},
	showWarnings: false,
};

export default injectIntl(FacialModalCalendar);
