import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const Arrow = ({ className, size, direction, onClick }) => (
	<div
		className={`Arrow Arrow--${direction} Arrow--${size} ${className}`}
		onKeyUp={() => {}}
		onClick={onClick}
		role="button"
		tabIndex={0}
	>
		<svg viewBox="0 0 45 45" version="1.1">
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-575.000000, -1649.000000)">
					<g transform="translate(575.000000, 1649.000000)">
						<circle fill="#212121" cx="22.5" cy="22.5" r="22.5" />
						<polygon fill="#FFFFFF" fillRule="nonzero" opacity="0.839999974" transform="translate(22.776429, 22.841794) scale(-1, 1) translate(-22.776429, -22.841794) " points="32.6957147 21.9151415 16.3748155 21.9151415 21.5089645 16.735152 20.2047621 15.4285714 12.8571429 22.8417944 20.2047621 30.2550173 21.4997799 28.9484368 16.3748155 23.7684472 32.6957147 23.7684472" />
					</g>
				</g>
			</g>
		</svg>
	</div>
);

Arrow.propTypes = {
	className: PropTypes.string,
	size: PropTypes.oneOf(['small', 'medium', 'big']),
	direction: PropTypes.oneOf(['right', 'left']),
	onClick: PropTypes.func
};

Arrow.defaultProps = {
	className: '',
	size: 'medium',
	direction: 'right',
	onClick: () => {}
};

export default Arrow;
