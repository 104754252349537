import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const Check = ({className, active, onClick}) => (
	<div
		className={`Check ${active ? 'Check--active' : null} ${className}`}
		onKeyUp={() => {}}
		onClick={onClick}
		role="button"
		tabIndex={0}
	>
		<svg className="Check__icon" viewBox="0 0 48 48" version="1.1">
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.839999974">
				<g transform="translate(-1166.000000, -1352.000000)" fill="#FFFFFF">
					<g transform="translate(1166.000000, 1352.000000)">
						<circle cx="24" cy="24" r="23" fill="#FFFFFF" opacity=".5" />
						<path d="M24,0 C37.2,0 48,10.8 48,24 C48,37.2 37.2,48 24,48 C10.8,48 0,37.2 0,24 C0,10.8 10.8,0 24,0 Z M19.2,36 L40.8,14.4 L37.44,11.04 L19.2,29.28 L10.56,20.64 L7.2,24 L19.2,36 Z" />
					</g>
				</g>
			</g>
		</svg>
	</div>
);

Check.propTypes = {
	className: PropTypes.string,
	active: PropTypes.bool,
	onClick: PropTypes.func
};

Check.defaultProps = {
	className: '',
	active: false,
	onClick: () => {}
};

export default Check;
