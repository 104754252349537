import StringParser from '../StringParser';

/* global window */

class General {
	static getPageTitle(pathName, orgName = '') {
		let title = '';

		if (pathName.startsWith('/gallery')) {
			title = 'Galeria';
		}

		return `${StringParser.capitalize(orgName)}${title ? ` - ${title}` : ''}`;
	}

	static getCurrentLocation() {
		return window.location.hostname;
	}

	static getWebName(domain) {
		const roverpixWebNames = ['127.0.0.1', '0.0.0.0', 'localhost'];

		if (roverpixWebNames.includes(domain)) {
			return 'fotos';
		}

		return domain.split('.roverpix.com')[0];
	}

	static getWebNameFromCurrentLocation() {
		const currentLocation = General.getCurrentLocation();

		return General.getWebName(currentLocation);
	}

	static downloadURI(uri) {
		const link = document.createElement("a");
		link.href = uri;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
}

export default General;
