import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import './index.sass';

class CardInput extends React.Component {
	render() {
		const {className, disabled, value, onChange} = this.props;

		return (
			<InputMask
				className={`CardInput ${className}`}
				name="card"
				inputRef={(c) => { this.input = c; }}
				ref={this.cardInput}
				mask="**-****-***"
				placeholder="00-0000-000"
				disabled={disabled}
				value={value}
				onChange={onChange}
			/>
		);
	}
}

CardInput.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	value: PropTypes.string,
	onChange: PropTypes.func
};

CardInput.defaultProps = {
	className: '',
	disabled: false,
	value: '',
	onChange: () => {}
};

export default CardInput;
