import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const Logo = ({className, name}) => {
	if (name) {
		return (
			<img
				className={`Logo ${className}`}
				src={`https://s3.amazonaws.com/roverpix-logos/${name.toLowerCase()}.png`}
				alt=""
			/>
		);
	}

	return null;
};

Logo.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string
};

Logo.defaultProps = {
	className: '',
	name: ''
};

export default Logo;
