import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import Form from '../../Atoms/Form';
import Text from '../../Atoms/Text';
import Input from '../../Atoms/Input';
import Button from '../../Atoms/Button';

import CardsBanner from '../../../assets/images/cards_banner.png';

import './index.sass';

/* global pagarme */

const CreditCardForm = ({ loading, error, onPay, intl }) => {
	const [creditCard, setCreditCard] = useState({})
	const [invalidFields, setInvalidFields] = useState([]);
	const onChangeField = (fieldName, value) => {
		const updatedCreditCard = { ...creditCard };

		updatedCreditCard[fieldName] = value;

		setCreditCard({ ...updatedCreditCard });
	};
	const validateForm = () => {
		const { card_number } = creditCard;

		if (!card_number) return;

		const cardValidations = pagarme.validate({card: creditCard});
		const { card = {} } = cardValidations;
		const updatedInvalidFields = [...invalidFields];

		Object.keys(card).forEach((fieldName) => {
			if (card.hasOwnProperty(fieldName) && !card[fieldName]) {
				if (!invalidFields.includes(fieldName)) {
					updatedInvalidFields.push(fieldName);
				}
			} else if (card.hasOwnProperty(fieldName) && card[fieldName]) {
				if (invalidFields.includes(fieldName)) {
					updatedInvalidFields.pop(fieldName);
				}
			}
		});

		setInvalidFields([ ...updatedInvalidFields ]);
	}
	useEffect(validateForm, [creditCard]);
	const { formatMessage } = intl;
	const messages = defineMessages({
		creditCardsAccepted: {
			id: 'creditCardForm.creditCardsAccepted',
			defaultMessage: 'Cartões de créditos aceitos'
		},
		creditCardNumber: {
			id: 'creditCardForm.creditCardNumber',
			defaultMessage: 'Número do cartão'
		},
		creditCardName: {
			id: 'creditCardForm.creditCardName',
			defaultMessage: 'Nome (como escrito no cartão)'
		},
		creditCardExpMonth: {
			id: 'creditCardForm.creditCardExpMonth',
			defaultMessage: 'Mês'
		},
		creditCardExpYear: {
			id: 'creditCardForm.creditCardExpYear',
			defaultMessage: 'Ano'
		},
		creditCardSecurityCode: {
			id: 'creditCardForm.creditCardSecurityCode',
			defaultMessage: 'Código de segurança'
		},
		transactionRefused: {
			id: 'creditCardForm.transactionRefused',
			defaultMessage: 'Transação recusada'
		},
		pay: {
			id: 'creditCardForm.pay',
			defaultMessage: 'Pagar'
		}
	});
	const { card_expiration_month } = creditCard;

	return (
		<Form
			id="credit_card_form"
			className="CreditCardForm"
			onSubmit={(e) => onPay(e, creditCard)}
		>
			<Text variant="caption">
				{formatMessage(messages.creditCardsAccepted)}
			</Text>
			<img
				alt="Credit cards banner"
				style={{width: '100%'}}
				src={CardsBanner}
			/>
			<Input
				id="card_number"
				className="CreditCardForm__input CreditCardForm__input--full"
				type="text"
				label={formatMessage(messages.creditCardNumber)}
				required
				error={invalidFields.includes('card_number')}
				onChange={(e) => onChangeField('card_number', e.target.value)}
			/>
			<Input
				id="card_holder_name"
				className="CreditCardForm__input CreditCardForm__input--full"
				type="text"
				label={formatMessage(messages.creditCardName)}
				autoComplete="cc-name"
				required
				error={invalidFields.includes('card_holder_name')}
				onChange={(e) => onChangeField('card_holder_name', e.target.value)}
			/>
			<div className="CreditCardForm__numbers">
				<Input
					id="card_expiration_month"
					className="CreditCardForm__input"
					type="number"
					label={formatMessage(messages.creditCardExpMonth)}
					min={1}
					max={12}
					required
					error={card_expiration_month > 12 || card_expiration_month < 1 || invalidFields.includes('card_expiration_date')}
					onChange={(e) => onChangeField('card_expiration_month', e.target.value)}
				/>
				<Input
					id="card_expiration_year"
					className="CreditCardForm__input"
					type="number"
					label={formatMessage(messages.creditCardExpYear)}
					min={2020}
					max={3000}
					required
					error={invalidFields.includes('card_expiration_date')}
					onChange={(e) => onChangeField('card_expiration_year', e.target.value)}
				/>
				<Input
					id="card_cvv"
					className="CreditCardForm__input"
					type="number"
					label={formatMessage(messages.creditCardSecurityCode)}
					min={0}
					max={9999}
					required
					error={invalidFields.includes('card_cvv')}
					onChange={(e) => onChangeField('card_cvv', e.target.value)}
				/>
			</div>
			<div id="field_errors" />
			{error && (
				<div className="CreditCardForm__error">
					{error === 'REFUSED' && (
						<Text>{formatMessage(messages.transactionRefused)}</Text>
					)}
				</div>
			)}
			<Button
				className="CreditCardForm__pay"
				color="secondary"
				loading={loading}
				size="medium"
				type="submit"
				disabled={invalidFields.length > 0}
			>
				{formatMessage(messages.pay)}
			</Button>
		</Form>
	)
}

CreditCardForm.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.string,
	onPay: PropTypes.func,
	intl: PropTypes.shape().isRequired
}

CreditCardForm.defaultProps = {
	loading: false,
	error: '',
	onPay: () => {}
}

export default injectIntl(CreditCardForm);
