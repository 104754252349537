/* global fetch */

export const FETCH_ORGANIZATION_BEGIN = 'FETCH_ORGANIZATION_BEGIN';
export const fetchOrganizationBegin = () => ({
	type: FETCH_ORGANIZATION_BEGIN
});

export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS';
export const fetchOrganizationSuccess = organization => ({
	type: FETCH_ORGANIZATION_SUCCESS,
	payload: { organization }
});

export const FETCH_ORGANIZATION_FAILURE = 'FETCH_ORGANIZATION_FAILURE';
export const fetchOrganizationFailure = error => ({
	type: FETCH_ORGANIZATION_FAILURE,
	payload: { error }
});


export function fetchOrganization(webName) {
	return (dispatch) => {
		dispatch(fetchOrganizationBegin());
		return fetch(`${process.env.ROVERPIX_API}/api/v1/organization/${webName.toLowerCase()}`)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(fetchOrganizationFailure(json.message));
				} else {
					dispatch(fetchOrganizationSuccess(json.organization));
				}
			}).catch((e) => {
				dispatch(fetchOrganizationFailure(e.toString()));
			});
	};
}
