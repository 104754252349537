import React from 'react';
import PropTypes from 'prop-types';
import Photo from '../../Atoms/Photo';
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from '../../Atoms/Loader';

import './index.sass';

const CartItem = ({ className, selected, itemMedia, onSelect, onRemove, onLoading}) => (
	<div
		className={`CartItem ${className} ${selected ? 'CartItem--selected' : null}`}
		onClick={onSelect}
	>
		<div className="CartItem__photo__container">
			<Photo
				className="PhotoGallery__item__photo"
				alt="Customer photo"
				id={itemMedia.id}
				filename={itemMedia.thumb_filename}
				type="thumb"
				mediaType={itemMedia.type}
			/>
		</div>
		<div
			className="CartItem__delete"
			onClick={(e) => {
				e.stopPropagation()
				onRemove(e)
			}}
			onKeyUp={() => {}}
			role="button"
			tabIndex={0}
		>
			{onLoading ? (
				<Loader size="small"/>
			) : (
				<DeleteIcon className="CartItem__delete__icon" />
			)}
		</div>
	</div>
);

CartItem.propTypes = {
	className: PropTypes.string,
	selected: PropTypes.bool,
	itemMedia: PropTypes.shape({
		thumb_path: PropTypes.string
	}),
	onSelect: PropTypes.func,
	onRemove: PropTypes.func
};

CartItem.defaultProps = {
	className: '',
	selected: false,
	itemMedia: {},
	onSelect: () => {},
	onRemove: () => {}
};

export default CartItem;
