import React, {useState} from 'react';
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";

import CardSelectionAccordion from './CardSelectionAccordion';
import Button from '../../../../Atoms/Button';
import Text from '../../../../Atoms/Text';

import './index.sass';

const FacialModalCardSelection = ({ className, cardList, selectedCards, intl, onSubmitMoreFaces, returnButton }) => {
	const [checkedItems, setCheckedItems] = useState({});
	const { formatMessage } = intl;
	const messages = defineMessages({
		cardSelectionSend: {
			id: 'facialModal.cardSelectionSend',
			defaultMessage: "Selecionar",
		},
		cardSelection: {
			id: 'facialModal.cardSelection',
			defaultMessage: "Selecione um ou mais cartões que contenha suas fotos.",
		},
		cardSelectionPreview: {
			id: "facialModal.cardSelectionPreview",
			defaultMessage: "(Esta é apenas uma prévia, todas as fotos  estarão disponíveis na galeria)",
		},
		searchMoreFaces: {
			id: 'facialModal.searchMoreFaces',
			defaultMessage: "Procurar mais faces",
		},
		returnButton: {
			id: 'facialModal.returnButton',
			defaultMessage: "Voltar",
		},
	});

	const handleUpdateCheckedItems = (value, idx) => {
		setCheckedItems({
			...checkedItems,
			[idx]: value
		})
	}

	const onSubmit = () => {
		selectedCards( Object.values(checkedItems).filter(it => it))
	};

	return (
		<div className={`FacialModalCardSelection ${className}`}>
			<div className="FacialModalCardSelection__Text">
				<Text>
					{formatMessage(messages.cardSelection)}
				</Text>
				<Text className="FacialModalCardSelection__Text__Subtitle" variant="caption">
					{formatMessage(messages.cardSelectionPreview)}
				</Text>
			</div>
			<div className="FacialModalCardSelection__List">
				{cardList.map((item, index) => (
					<CardSelectionAccordion key={index} item={item} onChangeChecked={handleUpdateCheckedItems} index={index} checked={checkedItems[index] ?? false}/>
				))}
			</div>
			<div className="FacialModalCardSelection__Buttons">
				<Button onClick={onSubmit} color="secondary">
					{formatMessage(messages.cardSelectionSend)}
				</Button>
				<Button onClick={onSubmitMoreFaces} color="secondary">
					{formatMessage(messages.searchMoreFaces)}
				</Button>
				<Button onClick={returnButton}>
					{formatMessage(messages.returnButton)}
				</Button>
			</div>
		</div>
	);
};

FacialModalCardSelection.propTypes = {
	className: PropTypes.string,
	cardList: PropTypes.array.isRequired,
	selectedCards: PropTypes.func.isRequired,
	returnButton: PropTypes.func,
	onSubmitMoreFaces: PropTypes.func,
};

FacialModalCardSelection.defaultProps = {
	className: "",
	onSubmitMoreFaces: () => {},
	returnButton: () => {},
};

export default injectIntl(FacialModalCardSelection);
