import React, { useState } from "react";
import { injectIntl } from "react-intl";

import "./index.scss";

const Tooltip = ({ children, className, onClick, hasBeenVisible, text }) => {
	const [isVisible, setIsVisible] = useState(true);

	return (
		<div className={`ToolTip ${className}`}>
			{isVisible && !hasBeenVisible && (
				<div className="ToolTip__Container">
					<button
						className="ToolTip__Button"
						onClick={() => {
							onClick()
							setIsVisible(false)
						}}
					>
						x
					</button>
					<p className="ToolTip__Text">{text}</p>
				</div>
			)}
			{children || null}
		</div>
	);
};

export default injectIntl(Tooltip);
