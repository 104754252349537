import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import {default as ReactHelmet} from 'react-helmet';
import {General} from '../../../shared';

const Helmet = ({location, orgName}) => (
	<ReactHelmet
		title={General.getPageTitle(location.pathname, orgName)}
	>
		{orgName ? (
			<link
				rel="shortcut icon"
				href={`https://s3.amazonaws.com/roverpix-favicons/${orgName.toLowerCase()}.ico`}
				type="image/x-icon"
			/>
		) : null}
	</ReactHelmet>
);

Helmet.propTypes = {
	location: ReactRouterPropTypes.location.isRequired,
	orgName: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	orgName: state.organization.organization && state.organization.organization.name
		? state.organization.organization.name
		: ''
});

export default withRouter(connect(mapStateToProps)(Helmet));
