import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import Text from '../Text';
import { defineMessages, injectIntl } from "react-intl";

import './index.sass';

class Input2FA extends React.Component {
	render() {
		const {className, disabled, value, onChange, intl} = this.props;
		const {formatMessage} = intl;
		const messages = defineMessages({
			label: {
				id: "Input2FA.title",
				defaultMessage: "Código de acesso:",
			},
		});

		return (
			<div className={`Input2FA ${className}`}>
				<Text className="Input2FA__Text" variant="subtitle1" align="left">
					{formatMessage(messages.label)}
				</Text>
				<InputMask
					className={`Input2FA__Input ${className}`}
					inputRef={(c) => { this.input = c; }}
					ref={this.cardInput}
					mask="******"
					placeholder="000000"
					disabled={disabled}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}
}

Input2FA.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	value: PropTypes.string,
	onChange: PropTypes.func,
};

Input2FA.defaultProps = {
	className: '',
	disabled: false,
	value: '',
	onChange: () => {}
};

export default injectIntl(Input2FA);
