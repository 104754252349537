import React from 'react';
import PropTypes from 'prop-types';
import MaterialButton from '@material-ui/core/Button';
import Loader from '../Loader';

import './index.sass';

const SIZES = ['small', 'medium', 'large'];

const Button = ({
	className,
	size,
	type,
	color,
	loading,
	children,
	onClick,
	isLink,
	component,
	disabled
}) => (
	!isLink ? (
		<MaterialButton
			className={
				`Button Button--${color}
				${loading ? 'Button--loading' : null}
				${className}`
			}
			variant={color === 'tertiary' ? 'outlined' : 'contained'}
			component={component}
			size={size}
			disabled={loading || disabled}
			onClick={onClick}
			type={type}
		>
			{loading ? (
				<Loader className="Button__loader" size="small" />
			) : children}
		</MaterialButton>
	) : (
		<button
			className={
				`Button Button--${color}
				${loading ? 'Button--loading' : null}
				${className}
				Button--link`
			}
			variant={color === 'tertiary' ? 'outlined' : 'contained'}
			size={size}
			type={type}
			disabled={loading || disabled}
			onClick={onClick}
		>
			{loading ? (
				<Loader className="Button__loader" />
			) : children}
		</button>
	)
);

Button.propTypes = {
	className: PropTypes.string,
	type: PropTypes.oneOf([
		'button', 'submit'
	]),
	size: PropTypes.oneOf(SIZES),
	color: PropTypes.oneOf([
		'primary', 'secondary', 'tertiary', 'quaternary', 'error'
	]),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func
};

Button.defaultProps = {
	className: '',
	type: 'button',
	size: 'medium',
	color: 'primary',
	children: 'Button',
	loading: false,
	disabled: false,
	onClick: () => {}
};

export default Button;
