import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';
import loader from '../../../assets/gifs/load.gif'

const Loader = ({ className, size }) => (
	<img
		className={`Loader ${className} Loader--${size}`}
		src={loader}
		alt="loader"
	/>
);

Loader.propTypes = {
	className: PropTypes.string,
	size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large'])
};

Loader.defaultProps = {
	className: '',
	size: 'medium'
};

export default Loader;
