import {
	FETCH_ORGANIZATION_BEGIN,
	FETCH_ORGANIZATION_SUCCESS,
	FETCH_ORGANIZATION_FAILURE
} from '../actions';

const initialState = {
	organization: {
		name: '',
		web_name: '',
		url: '',
		contact_email: ''
	},
	loading: false,
	error: ''
};

const parseConfig = (config) => {
	try {
		return JSON.parse(config)
	} catch (error) {
		return {}
	}
}

const parseOrganization = (organization) => {
	return {
		...organization,
		features_organization: {
			...organization.features_organization,
			config: organization.features_organization && organization.features_organization.config ? parseConfig(organization.features_organization.config) : {}
		}
	}
}

export default function photoReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_ORGANIZATION_BEGIN:
		return {
			...state,
			loading: true,
			error: ''
		};

	case FETCH_ORGANIZATION_SUCCESS:

		return {
			...state,
			loading: false,
			organization: parseOrganization(action.payload.organization)
		};

	case FETCH_ORGANIZATION_FAILURE:
		return {
			...state,
			loading: false,
			error: action.payload.error,
			organization: {}
		};

	default:
		return state;
	}
}
