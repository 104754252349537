import React from 'react';
import ZammadChat from '../../Atoms/ZammadChat'
import './index.sass';

const MainTemplate = ({ children }) => (
	<div className="MainTemplate">
		{children}
		<ZammadChat />
	</div>
);

export default MainTemplate;