import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, injectIntl} from 'react-intl';
import { connect } from 'react-redux';
import uuid from 'uuid';

import './index.sass';

import CloseIcon from '@material-ui/icons/Close';
import Text from '../../Atoms/Text';
import Button from '../../Atoms/Button';
import AddPhotoSuccessModal from '../../Molecules/AddPhotoSuccessModal';


class SendPhotoForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			photos: [],
			loading: false,
			error: null,
			card: null,
			success: false,
			cardToAddPhotos: null
		}
	}

	handleAddPhoto = (e) => {
		const {photos} = this.state;
		const files = e.target.files;
		const newPhotos = [];

		for (var i = 0; i < files.length; i++) {
			newPhotos.push({
				id: uuid(),
				file: files.item(i),
				url: URL.createObjectURL(files.item(i))
			})
		}

		this.setState({
			photos: [...photos, ...newPhotos]
		})
	}

	handleSendPhotos = () => {
		const {organization: orgState, cardCode, onSuccess} = this.props;
		const {organization = {}} = orgState;
		const {id, features = {}} = organization;

		if (!id) return;

		this.setState({
			loading: true,
			success: false
		});

		const {photos, cardToAddPhotos} = this.state;
		const {site = {}} = features || {};
		const {must_edit_photo: mustEditPhoto} = site || {};

		const formData = new FormData();

		for (var i = 0; i < photos.length; i++) {
			formData.append('photos[]', photos[i].file);
		}

		let url = `${process.env.ROVERPIX_API}/api/v1/photo/${id}`;
		if (cardCode) {
			url = `${url}?card_code=${cardCode}`;
		} else if (cardToAddPhotos) {
			url = `${url}?card_code=${cardToAddPhotos}`;
		}
		if (mustEditPhoto) {
			const separator = cardCode || cardToAddPhotos ? '&' : '?';
			url = `${url}${separator}must_edit=True`;
		}

		const request = new Request(
			url,
			{
				method: 'post',
				body: formData
			}
		);

		fetch(request)
		.then(res => res.json())
		.then((json) => {
			if (json.error) {
				this.setState({
					loading: false,
					error: json.error,
					success: false,
				})
			} else {
				onSuccess()
				this.setState({
					loading: false,
					card: json.card_code,
					photos: [],
					success: true
				})
			}
		}).catch((e) => {
			this.setState({
				loading: false,
				success: false,
				error: e
			})
		});
	}

	render() {
		const {className, intl, canClose, onClose} = this.props;
		const {photos, error, card, loading, success} = this.state;
		const {formatMessage} = intl;
		const messages = defineMessages({
			subtitle: {
				id: 'sendPhoto.subtitle',
				defaultMessage: 'Adicione suas fotos pelo o botão abaixo:'
			},
			chooseYourPhotos: {
				id: 'sendPhoto.chooseYourPhotos',
				defaultMessage: 'Escolha suas fotos'
			},
			addMorePhotos: {
				id: 'sendPhoto.addMorePhotos',
				defaultMessage: 'Adicionar mais fotos'
			},
			sending: {
				id: 'sendPhoto.sending',
				defaultMessage: 'Enviando...'
			},
			error: {
				id: 'sendPhoto.error',
				defaultMessage: 'Algo de errado aconteceu!'
			},
			tryAgain: {
				id: 'sendPhoto.tryAgain',
				defaultMessage: 'Tentar novamente'
			},
			viewMyPhotos: {
				id: 'sendPhoto.viewMyPhotos',
				defaultMessage: 'Ver minhas fotos'
			},
			sendPhotos: {
				id: 'sendPhoto.sendPhotos',
				defaultMessage: 'Enviar fotos'
			}
		});

		return (
			<div className={`SendPhotoForm ${className} ${photos.length === 0 ? 'SendPhotoForm--empty' : null}`}>
				<Text className="SendPhotoForm__subtitle" variant="subtitle1" paragraph>
					{formatMessage(messages.subtitle)}
				</Text>
				{loading && (
					<Text>
						{formatMessage(messages.sending)}
					</Text>
				)}
				{error && !loading && (
					<Text className="SendPhotoForm__error">
						{formatMessage(messages.error)}
					</Text>
				)}
				{!loading && !card && !error && (
					<>
						<Button
							className="SendPhotoForm__add"
							component="label"
							color={photos.length === 0 ? 'quaternary' : 'secondary'}
						>
							{photos.length === 0 ? (
								formatMessage(messages.chooseYourPhotos)
							) : (
								formatMessage(messages.addMorePhotos)
							)}
							<input
								type="file"
								hidden
								accept="image/x-png,image/jpeg"
								multiple
								onChange={this.handleAddPhoto}
							/>
						</Button>
						<div className="SendPhotoForm__list">
							{photos.map((photo) => (
								<div className="SendPhotoForm__item" key={photo.id}>
									<div className="SendPhotoForm__item__container">
										<img className="SendPhotoForm__item__photo" src={photo.url} alt="send" />
										<div
											className="SendPhotoForm__item__remove"
											onClick={() => {
												this.setState({
													photos: photos.filter((p) => p.id !== photo.id)
												})
											}}
										>
											<CloseIcon />
										</div>
									</div>
								</div>
							))}
						</div>
					</>
				)}
				<div className="SendPhotoForm__actions">
					{error ? (
						<Button
							className="SendPhotoForm__button"
							color='error'
							onClick={() => this.setState({ error: null })}
						>
							{formatMessage(messages.tryAgain)}
						</Button>
					) : (
						<Button
							className="SendPhotoForm__button"
							color='primary'
							onClick={() => {
								if (card) {
									this.setState({
										cardToAddPhotos: card,
										card: null,
										photos: []
									})
								} else {
									this.handleSendPhotos()
								}
							}}
							loading={loading}
						>
							{card ? formatMessage(messages.addMorePhotos) : formatMessage(messages.sendPhotos)}
						</Button>
					)}
					{canClose && (
						<Button
							className="SendPhotoForm__button"
							color='quaternary'
							onClick={() => onClose(card)}
							disabled={loading}
						>
							{formatMessage(messages.viewMyPhotos)}
						</Button>
					)}
				</div>
				<AddPhotoSuccessModal
					isOpened={success && card && !loading}
					cardCode={card ? `${card.substring(0, 2)}-${card.substring(2, 6)}-${card.substring(6, 9)}`.toUpperCase() : ''}
					onClose={() => this.setState({ success: false })}
				/>
			</div>
		);
	}
}

SendPhotoForm.propTypes = {
	className: PropTypes.string,
	cardCode: PropTypes.string,
	canClose: PropTypes.bool,
	onClose: PropTypes.func,
	onSuccess: PropTypes.func,
};

SendPhotoForm.defaultProps = {
	className: '',
	cardCode: '',
	canClose: true,
	onClose: () => {},
	onSuccess: () => {}
};

const mapStateToProps = state => ({
	organization: state.organization
});

export default connect(mapStateToProps, null)(injectIntl(SendPhotoForm));
