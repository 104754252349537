import React from 'react';
import PropTypes from 'prop-types';

import Text from '../../Atoms/Text';
import './index.sass';

const PriceList = ({ className, productName, productPrice }) => (
	<div className={`PriceList ${className}`}>
		<div className="PriceList__container">
			<Text  className="PriceList__container__product PriceList__container__product--name">
				{productName}
			</Text>
			<Text  className="PriceList__container__product PriceList__container__product--price">
				R$ {productPrice}
			</Text>
		</div>
	</div>
);

PriceList.propTypes = {
	className: PropTypes.string,
	productName: PropTypes.string,
	productPrice: PropTypes.number
};

PriceList.defaultProps = {
	className: '',
	productName: '',
	productPrice: 0.0
};

export default PriceList;
