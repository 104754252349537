import React from 'react';
import PropTypes from 'prop-types';
import { Calendar as ReactCalendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './index.sass';

const Calendar = ({ className, value, onChange, selectRange }) => (
	<ReactCalendar
		className={`Calendar ${className}`}
		locale="pt-BR"
		value={value}
		onChange={onChange}
		next2Label={null}
		prev2Label={null}
		selectRange={selectRange}
		allowPartialRange
	/>
)

Calendar.propTypes = {
	className: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.arrayOf(Date),
		PropTypes.instanceOf(Date)
	]),
	onChange: PropTypes.func,
	selectRange: PropTypes.bool,
};

Calendar.defaultProps = {
	className: '',
	value: null, 
	onChange: () => { },
	selectRange: true,
}

export default Calendar;
