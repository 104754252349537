import React from 'react';

import Modal from '../../Atoms/Modal';
import SendPhotoForm from '../SendPhotoForm';

const AddPhotoModal = ({className, isOpened, cardCode, onClose, onSuccess}) => (
	<Modal
		className={`AddPhotoModal ${className}`}
		isOpened={isOpened}
		onClose={onClose}
	>
		<SendPhotoForm
			canClose={false}
			cardCode={cardCode}
			onSuccess={onSuccess}
		/>
	</Modal>
)

export default AddPhotoModal;
