import React from 'react';
import PropTypes from 'prop-types';
import PhotoIcon from '@material-ui/icons/Photo';
import Text from '../../Atoms/Text';
import Button from '../../Atoms/Button';
import './index.sass';

import { defineMessages, injectIntl } from 'react-intl';

const MessageGallery = ({ className, type, onTryAgain, intl }) => {
    const { formatMessage } = intl;
    const messages = defineMessages({
        error: {
            id: 'messageGallery.error',
            defaultMessage: 'Alguma coisa deu errado. Por favor atualize a página e tente novamente.'
        },
        empty: {
            id: 'messageGallery.empty',
            defaultMessage: 'Sua galeria de fotos está vazia.'
        },
        notSoldMedias: {
            id: 'messageGallery.notSoldMedias',
            defaultMessage: 'Não há fotos compradas em sua galeria de fotos.'
        },
        tryAgain: {
            id: 'messageGallery.tryAgain',
            defaultMessage: 'Carregar novamente'
        }
    });
    return (
        <div className={`MessageGallery ${className}`}>
            <PhotoIcon className="MessageGallery__icon" />
            <Text className="MessageGallery__text">
                Opss!
            </Text>
            <Text className="MessageGallery__text">
                {formatMessage(messages[type])}
            </Text>
            {onTryAgain && (
                <Button className="MessageGallery__button" onClick={onTryAgain}>
                    {formatMessage(messages.tryAgain)}
                </Button>
            )}
        </div>
    );
};

MessageGallery.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(['error', 'empty', 'notSoldMedias'])
};

MessageGallery.defaultProps = {
    className: '',
    type: 'empty'
};

export default injectIntl(MessageGallery);
