import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import MainTemplate from '../../Templates/MainTemplate';
import Text from '../../Atoms/Text';
import Link from '../../Atoms/Link';
import Button from '../../Atoms/Button';

import './index.sass';

const errorPage = props => {
	const {intl} = props;
	const {formatMessage} = intl;
	const messages = defineMessages({
		message: {
			id: 'error.message',
			defaultMessage: 'Desculpe o transtorno, mas houve um erro ao carregar a pagina.'
		},
		contact: {
			id: 'error.contact',
			defaultMessage: 'Em caso do erro persistir, favor entrar em contato pelo o email: '
		},
		tryAgain: {
			id: 'error.tryAgain',
			defaultMessage: 'Tentar novamente'
		}
	});

	return (
		<MainTemplate>
			<div className="ErrorPage">
				<Text className="ErrorPage__message" variant="h5" paragraph>
					{formatMessage(messages.message)}
				</Text>
				<Button className="ErrorPage__button" onClick={() => window.location.reload()}>
					{formatMessage(messages.tryAgain)}
				</Button>
				<Text className="ErrorPage__contact" variant="subtitle1" paragraph>
					{formatMessage(messages.contact)}
					<Link
						href={'contato@roverpix.com'}
						subject='Ajuda para acessar o site'
						body={`URL do site: ${window.location.href}`}
					/>
				</Text>
			</div>
		</MainTemplate>
	)
};

export default injectIntl(errorPage);