/* global localStorage */

class LocalStorageMock {
	constructor() {
		this.localStorage = localStorage;
	}

	getItem(key) {
		return this.localStorage.getItem(key);
	}

	setItem(key, value) {
		this.localStorage.setItem(key, value.toString());
	}

	removeItem(key) {
		this.localStorage.removeItem(key);
	}
}

export default new LocalStorageMock();
