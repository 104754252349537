class Order {
	static getDinamicOrderItem (order, media_type="all") {
		// This method returns the first dynamic product of the order.
		// It used to work because there could be only one fixed the dinamic product on the website.
		// Now, we can have more, and this method may get deprecated soon.
		const { items = [] } = order;
		const dinamicItem = items.find((item) => {
			let unitsCheck = item.product_type.unities !== 0
			let mediaTypeCheck = Order.checkProductTypeMediaType(item.product_type, media_type)
			return unitsCheck && mediaTypeCheck
		});
		return dinamicItem || {};
	};

	static getOrderItems(order, media_type="all"){
		const { items = [] } = order;
		const filteredItems= items.filter((item) => {
			let unitsCheck = item.product_type.unities !== 0
			let mediaTypeCheck = Order.checkProductTypeMediaType(item.product_type, media_type)
			return unitsCheck && mediaTypeCheck
		});
		return filteredItems
	}
	static checkProductTypeMediaType(productType, mediaType){
		if (mediaType==="photo"){
			return productType.is_photo
		}
		else if (mediaType==="video"){
			return productType.is_video
		}
		return true
	};
	static getOrderAssociatedItems(order){
		const orderItems = Order.getOrderItems(order)
		const associatedItems = []
		orderItems.forEach(orderItem => orderItem.items.forEach(item => associatedItems.push(item)))
		return associatedItems
	}
	static getDinamicOrderItemMedias (order) {
		const associatedItems = Order.getOrderAssociatedItems(order);
		return associatedItems ? associatedItems : [];
	};

	static getOrderItemByAssociatedItemId(order, associatedItemId){
		return order.items.find(orderItem => orderItem.items.find(item => item.id === associatedItemId))
	}

	static isMediaOnOrder (mediaId, order) {
		const associatedItems = Order.getOrderAssociatedItems(order);
		const media = associatedItems.find((item) => (item.photo && item.photo.id === mediaId) || (item.video && item.video.id === mediaId));

		return media !== undefined;
	}

	static getAssociatedItemIdFromMediaId (order, mediaId) {
		const associatedItems = Order.getOrderAssociatedItems(order);
		const { id } = associatedItems.find((item) => (item.photo && item.photo.id === mediaId) || (item.video && item.video.id === mediaId));

		return id;
	}

	static getOrderItemByProductId (order, productId) {
		const { items = [] } = order;
		return items.find((item) => item.product_type.id === productId);
	}

	static hasAllDigital (order) {
		const { items: orderItems = [] } = order;

		return orderItems.some((orderItem) => {
			const { product_type } = orderItem;
			const { unities } = product_type;

			if (unities === 0) {
				return true;
			}

			return false;
		});
	}

	static getTotalOrderPhotos (order, photosLength) {
		let hasAllDigital = Order.hasAllDigital(order);
		let total = 0;
		const { items: orderItems = [] } = order;

		orderItems.forEach((orderItem) => {
			const { items: associatedItems = [] } = orderItem;
			const { product_type } = orderItem;
			const { unities } = product_type;

			total = total + associatedItems.length;

			if (unities === 0 && !hasAllDigital) {
				hasAllDigital = true;
			}
		});

		if (hasAllDigital) {
			return photosLength;
		}

		return total
	}
}

export default Order;