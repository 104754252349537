import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';

import './index.sass';

const Box = ({
	className, borderPosition, rounded, children
}) => (
	<Card className={`Box Box--border-${borderPosition} ${rounded ? 'Box--rounded' : null} ${className}`}>
		{children}
	</Card>
);

Box.propTypes = {
	className: PropTypes.string,
	borderPosition: PropTypes.oneOf([
		'left', 'right', 'top', 'bottom', 'vertical', 'horizontal', 'none', 'all'
	]),
	rounded: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};

Box.defaultProps = {
	className: '',
	borderPosition: 'all',
	rounded: true,
	children: null
};

export default Box;
