import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';

const BuyModalItem = ({ className, orderItem, intl}) => {
	const { formatMessage } = intl;
	const messages = defineMessages({
		photosTitle: {
			id: 'buyModal.photosTitle',
			defaultMessage: 'Fotos'
		},
		photos: {
			id: 'buyModal.photos',
			defaultMessage: '{itemsQuantity, plural, one {1 foto} other {# fotos}}'
		},
		videosTitle: {
			id: 'buyModal.videosTitle',
			defaultMessage: 'Videos'
		},
		videos: {
			id: 'buyModal.videos',
			defaultMessage: '{itemsQuantity, plural, one {1 video} other {# videos}}'
		},
		photosVideosTitle: {
			id: 'buyModal.photosVideosTitle',
			defaultMessage: 'Fotos + Videos'
		},
		photosVideos: {
			id: 'buyModal.photosVideos',
			defaultMessage: '{itemsQuantity, plural, one {1 item} other {# items}}'
		},
		total: {
			id: 'buyModal.table.total',
			defaultMessage: 'Total'
		},
	})
	const isVideo = orderItem.product_type.is_video
	const isPhoto = orderItem.product_type.is_photo
	const isPhotoAndVideo = isVideo && isPhoto
	return (
		<div className="BuyModal__table">
			<div className="BuyModal__table__item BuyModal__table__item--digital">
				<div className="BuyModal__table__item__title">
					{
						isPhotoAndVideo? formatMessage(messages.photosVideosTitle) :
						isPhoto? formatMessage(messages.photosTitle): formatMessage(messages.videosTitle)}
				</div>
				<div className="BuyModal__table__item__description">
					{ orderItem.items.length > 0 ?
						isPhotoAndVideo? formatMessage(messages.photosVideos, { itemsQuantity: orderItem.items.length })
						: isPhoto? formatMessage(messages.photos, { itemsQuantity: orderItem.items.length })
							: formatMessage(messages.videos, { itemsQuantity: orderItem.items.length })
						: "0"
					}
				</div>
			</div>
			<div className="BuyModal__table__item BuyModal__table__item--price">
				<div className="BuyModal__table__item__title">
					{formatMessage(messages.total)}
				</div>
				<div className="BuyModal__table__item__description">
					{`R$ ${orderItem.total || "0"}`}
				</div>
			</div>
		</div>
	);
};

BuyModalItem.propTypes = {
	className: PropTypes.string,
	intl: PropTypes.object.isRequired,
};

BuyModalItem.defaultProps = {
	className: '',
};

export default injectIntl(BuyModalItem);
