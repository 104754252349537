import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const Banner = ({ className, url, height }) => (
	<div className="iframeDiv">
		<iframe 
		src={url}
		border="0" 
		title="Advertisement Banner"
		height={height}
		> 
			<a href={url} rel="noopener noreferrer" alt="Advertisement Banner"> </a>
		</iframe>
	</div>
);

Banner.propTypes = {
	className: PropTypes.string,
	url: PropTypes.string,
	height: PropTypes.string
};

Banner.defaultProps = {
	className: '',
	url: '',
	height: "80px"
};

export default Banner;
