/* global fetch */

export const CLEAR_ORDER = 'CLEAR_ORDER';
export const clearOrder = () => ({
	type: CLEAR_ORDER,
});

export const FETCH_ORDER_BEGIN = 'FETCH_ORDER_BEGIN';
export const fetchOrderBegin = () => ({
	type: FETCH_ORDER_BEGIN
});

export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const fetchOrderSuccess = order => ({
	type: FETCH_ORDER_SUCCESS,
	payload: { order }
});

export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';
export const fetchOrderFailure = error => ({
	type: FETCH_ORDER_FAILURE,
	payload: { error }
});

const parseCards = (cards) => (typeof cards === 'string' ? [cards] : cards);

export function fetchOrder(cards, orgId) {
	return async (dispatch) => {
		cards = parseCards(cards)
		const headers = new Headers()
		headers.append("Content-Type", "application/json");
		dispatch(fetchOrderBegin());
		const myInit = {
			method: 'POST',
			headers: headers,
			body: JSON.stringify({ "cards": cards }),
		};

		let pdv = 'SITE'
		try {
			pdv = window.location.host
		} catch (e) { }

		let tz = null
		try {
			tz = Intl.DateTimeFormat().resolvedOptions().timeZone
		} catch (e) { }

		let url = `${process.env.ROVERPIX_API}/api/v1/order/${cards[0]}/${orgId}`;
		url = `${url}?pdv=${pdv}`;
		if (tz) url = `${url}&tz=${tz}`;

		const request = new Request(url, myInit);
		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(fetchOrderFailure(json.message));
				} else {
					dispatch(fetchOrderSuccess(json.order));
				}
			}).catch((e) => {
				dispatch(fetchOrderFailure(e.toString()));
			});
	};
}

export const ADD_CARD_BEGIN = 'ADD_CARD_BEGIN';
export const addCardBegin = () => ({
	type: ADD_CARD_BEGIN
});

export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';
export const addCardSuccess = order => ({
	type: ADD_CARD_SUCCESS,
	payload: { order }
});

export const ADD_CARD_FAILURE = 'ADD_CARD_FAILURE';
export const addCardFailure = error => ({
	type: ADD_CARD_FAILURE,
	payload: { error }
});

export function addCard(orderId, cardCode) {
	return (dispatch) => {
		dispatch(addCardBegin());

		const headers = new Headers();
		const myInit = { method: 'POST', headers };
		const request = new Request(`${process.env.ROVERPIX_API}/api/v1/order/${orderId}/card/${cardCode}`, myInit);

		headers.append("Content-Type", "application/json");
		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(addCardFailure(json.message));
				} else {
					dispatch(addCardSuccess(json.order));
				}
			}).catch((e) => {
				dispatch(addCardFailure(e.toString()));
			});
	};
}


export const REMOVE_CARD_BEGIN = 'REMOVE_CARD_BEGIN';
export const removeCardBegin = () => ({
	type: REMOVE_CARD_BEGIN
});

export const REMOVE_CARD_SUCCESS = 'REMOVE_CARD_SUCCESS';
export const removeCardSuccess = order => ({
	type: REMOVE_CARD_SUCCESS,
	payload: { order }
});

export const REMOVE_CARD_FAILURE = 'REMOVE_CARD_FAILURE';
export const removeCardFailure = error => ({
	type: REMOVE_CARD_FAILURE,
	payload: { error }
});

export function removeCard(orderId, cardCode) {
	return (dispatch) => {
		dispatch(removeCardBegin());

		const request = new Request(
			`${process.env.ROVERPIX_API}/api/v1/order/${orderId}/card/${cardCode}`,
			{ method: 'delete' }
		);

		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(removeCardFailure(json.message));
				} else {
					dispatch(removeCardSuccess(json.order));
				}
			}).catch((e) => {
				dispatch(removeCardFailure(e.toString()));
			});
	};
}

export const ADD_MEDIA_BEGIN = 'ADD_MEDIA_BEGIN';
export const addMediaBegin = (mediaId) => ({
	type: ADD_MEDIA_BEGIN,
	payload: { mediaId }
});

export const ADD_MEDIA_SUCCESS = 'ADD_MEDIA_SUCCESS';
export const addMediaSuccess = (order, mediaId) => ({
	type: ADD_MEDIA_SUCCESS,
	payload: { order, mediaId }
});

export const ADD_MEDIA_FAILURE = 'ADD_MEDIA_FAILURE';
export const addMediaFailure = (error, mediaId) => ({
	type: ADD_MEDIA_FAILURE,
	payload: { error, mediaId }
});

export function addMediaToOrder(orderId, orderItemId, mediaId, mediaType) {
	return (dispatch) => {
		dispatch(addMediaBegin(mediaId));

		const request = new Request(
			`${process.env.ROVERPIX_API}/api/v1/order/${orderId}/product/${orderItemId}/${mediaType}/${mediaId}`,
			{ method: 'post' }
		);

		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(addMediaFailure(json.message, mediaId));
				} else {
					dispatch(addMediaSuccess(json.order, mediaId));
				}
			}).catch((e) => {
				dispatch(addMediaFailure(e.toString()));
			});
	};
}

export const REMOVE_ORDER_MEDIA_BEGIN = 'REMOVE_ORDER_MEDIA_BEGIN';
export const removeMediaBegin = mediaId => ({
	type: REMOVE_ORDER_MEDIA_BEGIN,
	payload: { mediaId }
});

export const REMOVE_ORDER_MEDIA_SUCCESS = 'REMOVE_ORDER_MEDIA_SUCCESS';
export const removeMediaSuccess = (order, mediaId) => ({
	type: REMOVE_ORDER_MEDIA_SUCCESS,
	payload: { order, mediaId }
});

export const REMOVE_ORDER_MEDIA_FAILURE = 'REMOVE_ORDER_MEDIA_FAILURE';
export const removeMediaFailure = (error, mediaId) => ({
	type: REMOVE_ORDER_MEDIA_FAILURE,
	payload: { error, mediaId }
});

export function removeMediaFromOrder(orderId, orderItemId, mediaId) {
	return (dispatch) => {
		dispatch(removeMediaBegin(mediaId));

		const request = new Request(
			`${process.env.ROVERPIX_API}/api/v1/order/${orderId}/product/${orderItemId}/media/${mediaId}`,
			{ method: 'delete' }
		);

		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(removeMediaFailure(json.message, mediaId));
				} else {
					dispatch(removeMediaSuccess(json.order, mediaId));
				}
			}).catch((e) => {
				dispatch(removeMediaFailure(e.toString()));
			});
	};
}

export const ADD_ALL_DIGITAL_BEGIN = 'ADD_ALL_DIGITAL_BEGIN';
export const addAllDigitalBegin = () => ({
	type: ADD_ALL_DIGITAL_BEGIN
});

export const ADD_ALL_DIGITAL_SUCCESS = 'ADD_ALL_DIGITAL_SUCCESS';
export const addAllDigitalSuccess = order => ({
	type: ADD_ALL_DIGITAL_SUCCESS,
	payload: { order }
});

export const ADD_ALL_DIGITAL_FAILURE = 'ADD_ALL_DIGITAL_FAILURE';
export const addAllDigitalFailure = error => ({
	type: ADD_ALL_DIGITAL_FAILURE,
	payload: { error }
});

export function addAllDigital(orderId, productId) {
	return (dispatch) => {
		dispatch(addAllDigitalBegin());

		const request = new Request(
			`${process.env.ROVERPIX_API}/api/v1/order/${orderId}/product/${productId}`,
			{ method: 'post' }
		);

		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(addAllDigitalFailure(json.message));
				} else {
					dispatch(addAllDigitalSuccess(json.order));
				}
			}).catch((e) => {
				dispatch(addAllDigitalFailure(e.toString()));
			});
	};
}

export const REMOVE_ALL_DIGITAL_BEGIN = 'REMOVE_ALL_DIGITAL_BEGIN';
export const removeAllDigitalBegin = () => ({
	type: REMOVE_ALL_DIGITAL_BEGIN
});

export const REMOVE_ALL_DIGITAL_SUCCESS = 'REMOVE_ALL_DIGITAL_SUCCESS';
export const removeAllDigitalSuccess = order => ({
	type: REMOVE_ALL_DIGITAL_SUCCESS,
	payload: { order }
});

export const REMOVE_ALL_DIGITAL_FAILURE = 'REMOVE_ALL_DIGITAL_FAILURE';
export const removeAllDigitalFailure = error => ({
	type: REMOVE_ALL_DIGITAL_FAILURE,
	payload: { error }
});

export function removeAllDigital(orderId, productId) {
	return (dispatch) => {
		dispatch(removeAllDigitalBegin());

		const request = new Request(
			`${process.env.ROVERPIX_API}/api/v1/order/${orderId}/product/${productId}`,
			{ method: 'delete' }
		);

		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(removeAllDigitalFailure(json.message));
				} else {
					dispatch(removeAllDigitalSuccess(json.order));
				}
			}).catch((e) => {
				dispatch(removeAllDigitalFailure(e.toString()));
			});
	};
}

export const SAVE_ORDER_CUSTOMER_BEGIN = 'SAVE_ORDER_CUSTOMER_BEGIN';
export const saveOrderCustomerBegin = () => ({
	type: SAVE_ORDER_CUSTOMER_BEGIN
});

export const SAVE_ORDER_CUSTOMER_SUCCESS = 'SAVE_ORDER_CUSTOMER_SUCCESS';
export const saveOrderCustomerSuccess = order => ({
	type: SAVE_ORDER_CUSTOMER_SUCCESS,
	payload: { order }
});

export const SAVE_ORDER_CUSTOMER_FAILURE = 'SAVE_ORDER_CUSTOMER_FAILURE';
export const saveOrderCustomerFailure = error => ({
	type: SAVE_ORDER_CUSTOMER_FAILURE,
	payload: { error }
});

export function saveOrderCustomer(orderId, customer) {
	return (dispatch) => {
		dispatch(saveOrderCustomerBegin());

		const request = new Request(
			`${process.env.ROVERPIX_API}/api/v1/order/${orderId}/customer`,
			{
				method: 'post',
				headers: {'Content-type': 'application/json'},
				body: JSON.stringify({'customer': customer})
			}
		);

		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(saveOrderCustomerFailure(json.message));
				} else {
					dispatch(saveOrderCustomerSuccess(json.order));
				}
			}).catch((e) => {
				dispatch(saveOrderCustomerFailure(e.toString()));
			});
	};
}

export function saveOrderCustomerAddress(orderId, address) {
	return (dispatch) => {
		dispatch(saveOrderCustomerBegin());

		const request = new Request(
			`${process.env.ROVERPIX_API}/api/v1/order/${orderId}/customer`,
			{
				method: 'post',
				headers: {'Content-type': 'application/json'},
				body: JSON.stringify({'address': address})
			}
		);

		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(saveOrderCustomerFailure(json.message));
				} else {
					dispatch(saveOrderCustomerSuccess(json.order));
				}
			}).catch((e) => {
				dispatch(saveOrderCustomerFailure(e.toString()));
			});
	};
}

export const FINISH_BEGIN = 'FINISH_BEGIN';
export const finishBegin = () => ({
	type: FINISH_BEGIN
});

export const FINISH_SUCCESS = 'FINISH_SUCCESS';
export const finishSuccess = order => ({
	type: FINISH_SUCCESS,
	payload: { order }
});

export const FINISH_FAILURE = 'FINISH_FAILURE';
export const finishFailure = error => ({
	type: FINISH_FAILURE,
	payload: { error }
});

export function finishOrder(orderId) {
	return (dispatch) => {
		dispatch(finishBegin());

		const request = new Request(
			`${process.env.ROVERPIX_API}/api/v1/order/${orderId}/finish`,
			{
				method: 'post',
				headers: { 'Content-type': 'application/json' },
				body: JSON.stringify({})
			}
		);

		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(finishFailure(json.message));
				} else {
					dispatch(finishSuccess(json.order));
				}
			}).catch((e) => {
				dispatch(finishFailure(e.toString()));
			});
	};
}

export const CHANGE_SELECTED_MEDIA_ORDER_SUCCESS = 'CHANGE_SELECTED_MEDIA_ORDER_SUCCESS';
export const changeSelectedOrderMediaSuccess = mediaId => ({
	type: CHANGE_SELECTED_MEDIA_ORDER_SUCCESS,
	payload: mediaId
});

export function changeSelectedOrderMedia(mediaId) {
	return (dispatch) => {
		dispatch(changeSelectedOrderMediaSuccess(mediaId))
	}
}
