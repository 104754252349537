//
import React from 'react';
import ReactDOM from 'react-dom';

import './styles/main.sass';

// Redux imports
import {createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import reducer from './store/reducers';

// Router imports
import {BrowserRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';

// i18n
import {IntlProvider} from 'react-intl';

// Custom imports
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import {LocalStorage} from './shared';

// Sentry
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
	dsn: "https://146a6c9fd2be4f3baf8b94fed47fd7f6@o679601.ingest.sentry.io/5770001",
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 0.1,
	release: "roverpix-web-new@1.4.5"
});

// i18n configuration
if (!Intl.PluralRules) {
	require('intl-pluralrules/polyfill');
	require('@formatjs/intl-relativetimeformat/dist/locale-data/pt'); // Add locale data for de
}

if (!Intl.RelativeTimeFormat) {
	require('@formatjs/intl-relativetimeformat/polyfill');
	require('@formatjs/intl-relativetimeformat/dist/locale-data/pt'); // Add locale data for de
}

let messages;
let language = LocalStorage.getItem('locale') || 'pt';
try {
	/* global window */
	messages = require(`./locales/${window.location.host.replace('.roverpix.com', '')}.json`);
	if (messages) {
		messages = messages[language];
	}
} catch (e) {
	messages = require(`./locales/${language}.json`);
}

// Redux configuration
const history = createBrowserHistory();
const composeEnhancers =
	typeof window === 'object' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const store = createStore(
	connectRouter(history)(reducer),
	composeEnhancers(
		applyMiddleware(
			thunkMiddleware,
			routerMiddleware(history)
		)
	),
);

ReactDOM.render(
	<IntlProvider locale="pt" messages={messages}>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</IntlProvider>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
