import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import Form from '../../Atoms/Form';
import Input from '../../Atoms/Input';
import Select from '../../Atoms/Select';
import Button from '../../Atoms/Button';

import Countries from '../../../shared/Data/countries_br.json';
import CountriesInt from '../../../shared/Data/countries_int.json';

import './index.sass';

const AddressForm = ({ address: receivedAddress, loading, onSave, onCancel, intl }) => {
	const [address, setState] = useState(receivedAddress)
	const onChangeAddressField = (fieldName, value) => {
		const updatedAddress = { ...address };

		updatedAddress[fieldName] = value;

		setState({ ...updatedAddress })
	};
	const { formatMessage } = intl;
	const messages = defineMessages({
		street: {
			id: 'addressForm.street',
			defaultMessage: 'Rua'
		},
		number: {
			id: 'addressForm.number',
			defaultMessage: 'Número'
		},
		zipcode: {
			id: 'addressForm.zipcode',
			defaultMessage: 'CEP'
		},
		city: {
			id: 'addressForm.city',
			defaultMessage: 'Cidade'
		},
		state: {
			id: 'addressForm.state',
			defaultMessage: 'Estado'
		},
		country: {
			id: 'addressForm.country',
			defaultMessage: 'País'
		},
		next: {
			id: 'addressForm.next',
			defaultMessage: 'Próximo'
		},
		changeMyInfo: {
			id: 'addressForm.changeMyInfo',
			defaultMessage: 'Alterar meus dados'
		}
	});
	const locale = localStorage.getItem('locale') || 'br';

	return (
		<>
			<Form
				id="address_form"
				className="AddressForm"
				onSubmit={(e) => {
					e.preventDefault()
					onSave(address)
				}}
			>
				<Input
					id="street"
					className="AddressForm__input AddressForm__input--full"
					type="text"
					label={formatMessage(messages.street)}
					name="street"
					value={address.street || ''}
					required
					onChange={(e) => onChangeAddressField('street', e.target.value)}
				/>
				<Input
					id="street_number"
					className="AddressForm__input"
					type="text"
					label={formatMessage(messages.number)}
					value={address.street_number || ''}
					required
					onChange={(e) => onChangeAddressField('street_number', e.target.value)}
				/>
				<Input
					id="zipcode"
					className="AddressForm__input"
					type="text"
					label={formatMessage(messages.zipcode)}
					value={address.zipcode || ''}
					required
					onChange={(e) => onChangeAddressField('zipcode', e.target.value)}
				/>
				<Input
					id="city"
					className="AddressForm__input"
					type="text"
					label={formatMessage(messages.city)}
					value={address.city || ''}
					required
					onChange={(e) => onChangeAddressField('city', e.target.value)}
				/>
				<Input
					id="state"
					className="AddressForm__input"
					type="text"
					label="Estado:"
					value={address.state || ''}
					required
					onChange={(e) => onChangeAddressField('state', e.target.value)}
				/>
				<Select
					id="country-select"
					label={formatMessage(messages.country)}
					className="AddressForm__input AddressForm__input--full"
					value={address.country || ''}
					options={locale !== 'br' ? CountriesInt : Countries}
					required
					onChange={(e) => onChangeAddressField('country', e.target.value)}
				/>
				<div id="field_errors" />
				<Button
					className="AddressForm__save"
					color="secondary"
					size="medium"
					type="submit"
					disabled={!address?.country}
				>
					{formatMessage(messages.next)}
				</Button>
			</Form>
			{!loading && (
				<Button
					className="AddressForm__close"
					color="primary"
					isLink
					onClick={onCancel}
					size="medium"
				>
					{formatMessage(messages.changeMyInfo)}
				</Button>
			)}
		</>
	)
}

AddressForm.propTypes = {
	address: PropTypes.shape({}),
	onSave: PropTypes.func,
	intl: PropTypes.shape().isRequired
}

AddressForm.defaultProps = {
	address: {},
	onSave: () => {}
}

export default injectIntl(AddressForm);
