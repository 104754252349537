import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import Button from '../../Atoms/Button';
import Loader from '../../Atoms/Loader';
import CreditCardForm from '../../Molecules/CreditCardForm';
import PixForm from '../../Molecules/PixForm';

import './index.sass';

import { doPaymentCheckout, doPaymentPixCheckout, getPayment, changePaymentMethod } from '../../../store/actions';
import { Analytics } from '../../../shared';

/* global pagarme */
class PayForm extends React.Component {
	state = {
		method: !localStorage.getItem('locale') || localStorage.getItem('locale') === 'pt' ? 'pix' : 'creditCard',
		pagarmeLoading: false,
		finished: false
	};

	componentDidUpdate() {
		const { finished } = this.state;
		const { payment, onPaid } = this.props;
		const { loading, status } = payment;

		if (!finished && !loading && status === 'PAID') {
			this.setState({ finished: true });
			onPaid();
		}
	}

	onPayByCreditCard = (e, card) => {
		e.preventDefault();
		const self = this;
		const { orderId, organization: orgState, doPaymentCheckout } = this.props;
		const { card_number, card_holder_name, card_expiration_month, card_expiration_year, card_cvv } = card;
		const cardToSend = { card_number, card_holder_name, card_cvv };
		const { organization } = orgState;
		const { name: orgName } = organization;

		cardToSend.card_expiration_date = `${card_expiration_month}/${card_expiration_year}`;

		this.setState({ pagarmeLoading: true });
		pagarme.client.connect({ encryption_key: process.env.ROVERPIX_PAGARME_ENCRYPTION_KEY })
			.then(client => client.security.encrypt(cardToSend))
			.then(cardHash => {
				self.setState({ pagarmeLoading: false });
				doPaymentCheckout(orderId, cardHash);
				Analytics.sendEvent('paid', 'buying', orgName);
			})

		Analytics.sendEvent('paying', 'buying', orgName);
	}

	onPayByPix = () => {
		const { orderId, organization: orgState, doPaymentPixCheckout } = this.props;
		const { organization } = orgState;
		const { name: orgName } = organization;
		doPaymentPixCheckout(orderId);
		Analytics.sendEvent('paid', 'buying', orgName);
	}

	render () {
		const { orderId, payment, order, onCancel, intl } = this.props;
		const { method, pagarmeLoading } = this.state;
		const { loading: loadingPayment, error, pixText } = payment;
		const { loading: loadingOrder } = order;
		const { formatMessage } = intl;
		const messages = defineMessages({
			payByCreditCard: {
				id: 'payForm.payByCreditCard',
				defaultMessage: 'Pagar com cartão de crédito'
			},
			payByPix: {
				id: 'payForm.payByPix',
				defaultMessage: 'Pagar com PIX'
			},
			pay: {
				id: 'payForm.pay',
				defaultMessage: 'Pagar'
			},
			changeAddress: {
				id: 'payForm.changeAddress',
				defaultMessage: 'Alterar endereço'
			}
		});

		if (pagarmeLoading || loadingOrder) {
			return (
				<div className="PayForm__loader">
					<Loader />
				</div>
			)
		}

		return (
			<>
				{method === 'creditCard' ? (
					<CreditCardForm
						error={error}
						loading={pagarmeLoading || loadingPayment || loadingOrder}
						onPay={this.onPayByCreditCard}
					/>
				) : (
					<PixForm
						orderId={orderId}
						pixText={pixText}
						error={error}
						loading={pagarmeLoading || loadingPayment || loadingOrder}
						onPay={this.onPayByPix}
					/>
				)}
				{!pagarmeLoading && !loadingOrder && (
					<>
						{(!localStorage.getItem('locale') || localStorage.getItem('locale') === 'pt') && (
							<Button
								className="PayForm__change-method"
								color="secondary"
								isLink
								onClick={() => {
									this.setState((prevState) => ({ method: prevState.method === 'pix' ? 'creditCard' : 'pix' }));
									this.props.changePaymentMethod(this.state.method === 'pix' ? 'creditCard' : 'pix');
									}
								}
								size="medium"
							>
								{method === 'pix' ? (
									formatMessage(messages.payByCreditCard)
								) : (
									formatMessage(messages.payByPix)
								)}
							</Button>
						)}
						<Button
							className="PayForm__cancel"
							color="primary"
							isLink
							onClick={onCancel}
							size="medium"
						>
							{formatMessage(messages.changeAddress)}
						</Button>
					</>
				)}
			</>
		)
	}
}

PayForm.propTypes = {
	orderId: PropTypes.string.isRequired,
	onPaid: PropTypes.func.isRequired,
	onError: PropTypes.func,
	onEditCustomer: PropTypes.func,
	onCancel: PropTypes.func,
	intl: PropTypes.shape().isRequired
};

PayForm.propTypes = {
	onError: () => {},
	onEditCustomer: () => {},
	onCancel: () => {}
}

const mapStateToProps = state => ({
	payment: state.payment,
	order: state.order,
	organization: state.organization,
	method: state.payment.method
});

const mapActionsToProps = dispatch => ({
	getPayment: (orderId) => dispatch(getPayment(orderId)),
	doPaymentCheckout: (orderId, cardHash) => dispatch(doPaymentCheckout(orderId, cardHash)),
	doPaymentPixCheckout: (orderId) => dispatch(doPaymentPixCheckout(orderId)),
	changePaymentMethod: (method) => dispatch(changePaymentMethod(method))
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(PayForm));
