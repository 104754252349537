import {
	FETCH_PRODUCTS_BEGIN,
	FETCH_PRODUCTS_SUCCESS,
	FETCH_PRODUCTS_FAILURE
} from '../actions';

const initialState = {
	all_digital: {},
	products: [],
	loading: false,
	error: ''
};

export default function productsReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_PRODUCTS_BEGIN:
		return {
			...state,
			loading: true,
			error: ''
		};

	case FETCH_PRODUCTS_SUCCESS:
		return {
			...state,
			loading: false,
			products: action.payload.products,
			all_digital: action.payload.all_digital
		};

	case FETCH_PRODUCTS_FAILURE:
		return {
			...state,
			loading: false,
			error: action.payload.error,
			products: [],
			all_digital: {}
		};

	default:
		return state;
	}
}
