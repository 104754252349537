import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import {default as MaterialModal} from '@material-ui/core/Modal';

import './index.sass';

import Box from '../../Molecules/Box';

const Modal = ({
	className, isOpened, children, onClose
}) => (
	<MaterialModal
		className="Modal__root"
		BackdropProps={{
			className: "Modal__backdrop"
		}}
		open={isOpened}
		onClose={onClose}
		disableAutoFocus
	>
		<div className={`Modal ${className}`}>
			<div className="Modal__close" onClick={onClose}>
				<CloseIcon />
			</div>
			<Box borderPosition="none">
				{children}
			</Box>
		</div>
	</MaterialModal>
);

Modal.propTypes = {
	className: PropTypes.string,
	isOpened: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	onClose: PropTypes.func
};

Modal.defaultProps = {
	className: '',
	isOpened: false,
	children: null,
	onClose: PropTypes.func
};

export default Modal;
