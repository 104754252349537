import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const Timer = ({ className, time, text, onFinished }) => {
	const [counter, setCounter] = React.useState(time);
	React.useEffect(() => {
		if (counter > 0) {
			setTimeout(() => setCounter(counter - 1), 1000)
		} else {
			onFinished()
		}
	}, [counter, onFinished]);

	return (
		<div className={`Timer ${className}`}>
			<div>{text} {counter}...</div>
		</div>
	);
};

Timer.propTypes = {
	className: PropTypes.string,
	text: PropTypes.string,
	time: PropTypes.number,
	onFinished: PropTypes.func
};

Timer.defaultProps = {
	className: '',
	text: 'Tempo',
	time: 5,
	onFinished: () => {}
};

export default Timer;
