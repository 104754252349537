import React from 'react';
import PropTypes from 'prop-types';
import { SvgLoader, SvgProxy } from 'react-svgmt';

const ImgSvg = ({ className, name, onClick }) => {
	const svgFile = require(`../../../assets/icons/${name}.svg`);

	const svg = (
		<div className={`Icon ${className}`} onClick={onClick}>
			<SvgLoader path={svgFile}>
				<SvgProxy selector={'path'} />
			</SvgLoader>
		</div>
	)

	return svg
};

ImgSvg.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func
};

ImgSvg.defaultProps = {
	className: '',
	onClick: () => {}
};

export default ImgSvg;