import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader';

import MediaError from '../../../assets/images/media_error.jpg';

import './index.sass';


class Photo extends React.Component {
	state = {
		loading: true,
		cache: null
	}

	componentDidUpdate(prevProps) {
		const { id: lastId, avoidCache: lastAvoidCache } = prevProps
		const { id, avoidCache } = this.props
		const { loading } = this.state

		if (lastId && lastId !== id && !loading) {
			this.setState({
				loading: true
			})
		}

		if (!lastAvoidCache && avoidCache) {
			this.setState({
				cache: new Date().getTime()
			})
		}
	}

	render() {
		const { className, id, filename, alt, type, hasBorder, avoidCache, isExtra, mediaType } = this.props;
		const { loading, cache } = this.state;
		let photoSource = `${process.env.ROVERPIX_API}/api/v1/${isExtra ? 'extra' : mediaType}/download/${id}/${filename}?size=${type}`;

		if (avoidCache) photoSource = `${photoSource}&cache=${cache}`

		return (
			<>
				{loading && (
					<Loader size={type === 'thumb' ? 'small' : 'medium'}/>
				)}
				<img
					className={`Photo Photo--${type} ${className} ${hasBorder ? 'Photo--hasBorder' : ''} ${loading ? 'Photo--loading' : ''}`}
					src={photoSource}
					alt={alt}
					onLoad={() => this.setState({ loading: false })}
					onError={({ currentTarget }) => {
						this.setState({loading: false});
						currentTarget.onerror = null;
						currentTarget.src = MediaError;
					}}
				/>
			</>
		)
	}
}

Photo.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string.isRequired,
	filename: PropTypes.string.isRequired,
	alt: PropTypes.string,
	mediaType: PropTypes.oneOf([
		'photo', 'video'
	]),
	type: PropTypes.oneOf([
		'thumb', 'preview'
	]),
	hasBorder: PropTypes.bool,
	avoidCache: PropTypes.bool,
	isExtra: PropTypes.bool,
};

Photo.defaultProps = {
	className: '',
	alt: 'Customer photo',
	type: 'thumb',
	hasBorder: false,
	avoidCache: false,
	isExtra: false,
	mediaType: 'photo'
};

export default Photo;
