import React, { useState } from 'react';
import PropTypes from "prop-types";

import Text from '../../../../Atoms/Text';
import Checkbox from '../../../../Atoms/CheckBox';
import ArrowUp from '../../../../../assets/icons/arrowUp.svg';
import ArrowDown from '../../../../../assets/icons/arrowDown.svg';
import Photo from '../../../../Atoms/Photo';

import './index.sass';

const CardSelectionAccordion = ({ className, item, onChangeChecked, checked, index }) => {
	const [subnav, setSubnav] = useState(index === 0);
	const showSubnav = () => setSubnav(!subnav);

	return (
		<div className={`CardSelectionAccordion ${className}`}>
			<div className="CardSelectionAccordion__Row">
				<Checkbox
					className="CardSelectionAccordion__Row__Checkbox"
					onChange={() => onChangeChecked((checked ? "" : item.card), index)}
					checked={checked}
				/>
				<Text variant="subtitle1">
					{item.card}
				</Text>
				{subnav ? (
					<img className="CardSelectionAccordion__Row__Arrows" src={ArrowUp} alt="arrowUp" onClick={showSubnav}/>
				) : (
					<img className="CardSelectionAccordion__Row__Arrows" src={ArrowDown} alt="arrowDown" onClick={showSubnav}/>
				)}
			</div>
			{subnav && (
				<div className="CardSelectionAccordion__Subnav">
					{item.medias.map((media) => (
						<Photo className="CardSelectionAccordion__Subnav__Item" id={media.id} key={media.id} mediaType={media.type} filename={media.filename} type="thumb"/>
					))}
				</div>
			)}
		</div>
	);
};

CardSelectionAccordion.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object.isRequired,
	onChangeChecked: PropTypes.func.isRequired,
	checked: PropTypes.bool,
	index: PropTypes.number.isRequired,
};

CardSelectionAccordion.defaultProps = {
	className: "",
	checked: false,
};

export default CardSelectionAccordion;
