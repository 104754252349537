import React from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";

import Button from "../../../../Atoms/Button";
import Text from "../../../../Atoms/Text";
import Camera from "../../../../Atoms/Camera";
import { ReactComponent as Settings } from "../../../../../assets/icons/settings-white.svg";

import "./index.sass";

const FacialModalCamera = ({ className, imageSelected, intl, returnButton, error, handleTootilpClose, onSearching, disableButton, onClickSettings, mirrorCamera, chooseCamera, research, onSearchImage, counter, showCounter }) => {
	const { formatMessage } = intl;
	const messages = defineMessages({
		captureImage: {
			id: 'facialModal.captureImage',
			defaultMessage: "Capturar imagem",
		},
		instructions: {
			id: 'facialModal.instructions',
			defaultMessage: "(Tire fotos em diferentes ângulos e clique em pesquisar)",
		},
		instructionsCounter: {
			id: 'facialModal.instructionsCounter',
			defaultMessage: "Fotos tiradas: ",
		},
		returnButton: {
			id: 'facialModal.returnButton',
			defaultMessage: "Voltar",
		},
		searchError: {
			id: 'facialModal.searchError',
			defaultMessage: "Erro ao encontrar cartões. Por favor, verifique a data e tire mais fotos. Caso o erro persista, entre em contato com o suporte.",
		},
		researchError: {
			id: 'facialModal.researchError',
			defaultMessage: "Erro ao realizar nova busca, você será redirecionado para a tela de seleção de cartões em 5 segundos.",
		},
		search: {
			id: 'facialModal.cameraSearch',
			defaultMessage: "Pesquisar",
		},
		countdownText: {
			id: 'facialModal.countdownText',
			defaultMessage: "Você poderá tirar outra foto em:",
		},
	});

	return (
		<div className={`FacialModalCamera ${className}`}>
			<div className="FacialModalCamera__Section">
				<Button className="FacialModalCamera__Section__Button" color="tertiary" onClick={onClickSettings}>
					<Settings />
				</Button>
				<Text className="FacialModalCamera__Section__Text">
					{formatMessage(messages.captureImage)}
				</Text>
				<Text className="FacialModalCamera__Section__Text__Subtitle" variant="caption">
					{formatMessage(messages.instructions)}
				</Text>
				{showCounter &&
					<Text className="FacialModalCamera__Section__Text__Subtitle" variant="caption">
						{formatMessage(messages.instructionsCounter)}{counter}
					</Text>
				}
			</div>
			<Camera 
				className="FacialModalCamera__Camera" 
				onSelectedImage={imageSelected} 
				error={error}
				handleTootilpClose={handleTootilpClose}
				onSearching={onSearching}
				mirrored={mirrorCamera}
				facingMode={chooseCamera}
				errorTooltipText={research ? formatMessage(messages.researchError) : formatMessage(messages.searchError)}
				searchButtonText={formatMessage(messages.search)}
				onSearchImage={onSearchImage}
				countdownText={formatMessage(messages.countdownText)}
			/>
			<Button onClick={returnButton} disabled={disableButton}>
				{formatMessage(messages.returnButton)}
			</Button>
		</div>
	);
};

FacialModalCamera.propTypes = {
	className: PropTypes.string,
	imageSelected: PropTypes.func.isRequired,
	returnButton: PropTypes.func,
	error: PropTypes.bool,
	handleTootilpClose: PropTypes.func,
	onSearching: PropTypes.bool,
	disableButton: PropTypes.bool,
	onClickSettings: PropTypes.func,
	mirrorCamera: PropTypes.bool,
	chooseCamera: PropTypes.oneOf(["user", "environment"]),
	research: PropTypes.bool,
	onSearchImage: PropTypes.func.isRequired,
	counter: PropTypes.number.isRequired,
	showCounter: PropTypes.bool.isRequired,
};

FacialModalCamera.defaultProps = {
	className: "",
	returnButton: () => {},
	error: false,
	handleTootilpClose: () => {},
	onSearching: false,
	disableButton: false,
	onClickSettings: () => {},
	mirrorCamera: true,
	chooseCamera: "user",
	research: false,
};

export default injectIntl(FacialModalCamera);
