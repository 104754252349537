import {
	FETCH_MEDIAS_BEGIN,
	FETCH_MEDIAS_SUCCESS,
	FETCH_MEDIAS_FAILURE,
	SELECT_MEDIA_SUCCESS,
	UNSELECT_MEDIA_SUCCESS,
	GENERATE_ZIP_BEGIN,
	GENERATE_ZIP_SUCCESS,
	GENERATE_ZIP_FAILURE,
	CLEAR_MEDIAS,
	REMOVE_MEDIA_BEGIN,
	REMOVE_MEDIA_SUCCESS,
	REMOVE_MEDIA_FAILURE
} from '../actions';
import { General } from '../../shared';

const initialState = {
	soldMedias: null,
	notSoldMedias: null,
	extras: [],
	loading: false,
	error: '',
	zip: {
		loading: false,
		error: '',
		url: ''
	}
};

export default function mediaReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_MEDIAS_BEGIN:
	case REMOVE_MEDIA_BEGIN:
		return {
			...state,
			loading: true,
			error: ''
		};

	case FETCH_MEDIAS_SUCCESS:
		return {
			...state,
			loading: false,
			soldMedias: action.payload.soldMedias,
			notSoldMedias: action.payload.notSoldMedias,
			extras: action.payload.extras
		};

	case FETCH_MEDIAS_FAILURE:
	case REMOVE_MEDIA_FAILURE:
		return {
			...state,
			loading: false,
			error: action.payload.error
		};

	case SELECT_MEDIA_SUCCESS:
		return {
			...state,
			notSoldMedias: state.notSoldMedias.map((media) => {
				if (media.id === action.payload) {
					return {
						...media,
						selected: true
					}
				}
				return {
					...media
				}
			})
		};

	case UNSELECT_MEDIA_SUCCESS:
		return {
			...state,
			notSoldMedias: state.notSoldMedias.map((media) => {
				if (media.id === action.payload) {
					return {
						...media,
						selected: false
					}
				}
				return {
					...media
				}
			})
		};

	case GENERATE_ZIP_BEGIN:
		return {
			...state,
			zip: {
				...state.zip,
				loading: true
			}
		}
	case GENERATE_ZIP_SUCCESS:
		General.downloadURI(action.payload);
		return {
			...state,
			zip: {
				...state.zip,
				loading: false,
				url: action.payload,
				error: ''
			}
		}

	case GENERATE_ZIP_FAILURE:
		return {
			...state,
			zip: {
				...state.zip,
				loading: false,
				error: action.payload,
				url: ''
			}
		}

	case REMOVE_MEDIA_SUCCESS:
		return {
			...state,
			loading: false,
			soldMedias: state.soldMedias.filter((media) => media.id !== action.payload.id),
			notSoldMedias: state.notSoldMedias.filter((media) => media.id !== action.payload.id)
		}

	case CLEAR_MEDIAS:
		return {...initialState}

	default:
		return state;
	}
}
