import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {default as MaterialSelect} from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import './index.sass';

const Select = ({ id, className, label, value, required, options, onChange }) => (
	<FormControl required={required} className={`Select ${className}`}>
		<InputLabel
			id={`${id}-label`}
			className="Select__label"
		>
			{label}
		</InputLabel>
		<MaterialSelect
			labelId={`${id}-label`}
			id={id}
			className="Select__input"
			value={value}
			required={required}
			onChange={onChange}
		>
			{options.map(({value, label}) => (
				<MenuItem key={value} value={value}>{label}</MenuItem>
			))}
		</MaterialSelect>
	</FormControl>
);

Select.propTypes = {
	id: PropTypes.string.isRequired,
	className: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	required: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	})),
	onChange: PropTypes.func
};

Select.defaultProps = {
	className: '',
	label: '',
	required: false,
	options: [],
	onChange: () => {}
};

export default Select;
