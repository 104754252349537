import React from 'react';
import PropTypes from 'prop-types';

const PhotoDownload = ({ className, href, filename, onDownload }) => (
	<a
		className={`PhotoDownload ${className}`}
		href={href}
		onClick={onDownload}
		download={filename}
	>
		<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
			<circle fill="#ffffff" cx="12" cy="13" r="5" />
			<path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z" fill="#f57c00" />
		</svg>
	</a>
);

PhotoDownload.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string,
	filename: PropTypes.string,
	onDownload: PropTypes.func
};

PhotoDownload.defaultProps = {
	className: '',
	href: '',
	filename: '',
	onDownload: () => {}
};

export default PhotoDownload;
