import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, injectIntl} from 'react-intl';
import { Analytics } from '../../../shared';

import './index.sass';

import Modal from '../../Atoms/Modal';
import Button from '../../Atoms/Button';
import Form from '../../Atoms/Form';
import CardInput from '../../Atoms/CardInput';
import Text from '../../Atoms/Text';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DeleteIcon from '@material-ui/icons/Delete';

class AddCardModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showInput: false,
			invalidCard: false,
			cardCode: ''
		};
	}

	showInput = () => {
		this.setState({
			showInput: true
		})
	}

	setInvalidCard = (status = false) => {
		this.setState({
			invalidCard: status
		});
	}

	parseCard = (e = null) => {
		if (e) {
			e.preventDefault();
		}

		const { onAddCard, organizationName } = this.props;
		const {cardCode} = this.state;

		if (cardCode.length < 9) {
			this.setInvalidCard(true);
		} else {
			this.setInvalidCard(false);
			onAddCard(cardCode.replace(/[-,_]/g, '').toUpperCase());
			Analytics.sendEvent('card_read', 'browsing', organizationName);
		}
	}

	closeModal = () => {
		this.setState({
			showInput: false
		});
		this.props.onClose()
	}

	render() {
		const {
			className,
			isOpened,
			invalidCard:
			receivedInvalidCard,
			intl,
			mainCard,
			cards,
			onRemoveCard
		} = this.props;
		const { cardCode, showInput, loading, invalidCard } = this.state;
		const {formatMessage} = intl;
		const messages = defineMessages({
			title: {
				id: 'AddCardModal.title',
				defaultMessage: '{cards, plural, one {1 cartão adicionado} other {# cartões adicionados}}'
			},
			add: {
				id: 'AddCardModal.add',
				defaultMessage: 'Adicionar outro cartão'
			},
			close: {
				id: 'AddCardModal.close',
				defaultMessage: 'Ver galeria'
			},
			invalidCard: {
				id: 'AddCardModal.invalidCard',
				defaultMessage: 'Cartão inválido'
			},
			save: {
				id: 'AddCardModal.save',
				defaultMessage: 'Salvar'
			},
		});

		return (
			<Modal
				className={`AddCardModal ${className}`}
				isOpened={isOpened}
				onClose={this.closeModal}
			>
				<div className="AddCardModal__title">
					<Text className="AddCardModal__title__text" variant="caption">
						{formatMessage(messages.title, { cards: cards.length })}
					</Text>
				</div>
				{cards.map(card => (
					<div className="AddCardModal__table" key={card.id}>
						<div className="AddCardModal__table__cardCode">
							<CreditCardIcon
								className="AddCardModal__table__icon"
							/>
							<Text
								className="AddCardModal__table__cardCode__text"
								variant="caption"
							>
								{card.card}
							</Text>
						</div>
						{card.card !== mainCard && (
							<div className="AddCardModal__table__action">
								<DeleteIcon
									className="AddCardModal__table__icon AddCardModal__table__icon--delete"
									onClick={() => onRemoveCard(card.card)}
								/>
							</div>
						)}
					</div>
				))}
				<div className="AddCardModal__action">
					{showInput && (
						<div>
							<Form
								onSubmit={this.parseCard}
							>
								<CardInput
									className="AddCardModal__action__input"
									ref={(c) => { this.cardInput = c; }}
									disabled={loading}
									value={cardCode}
									onChange={(e) => {
										this.setState({
											cardCode: e.target.value
										})
									}}
								/>
								<Button
									className="AddCardModal__action__button"
									onClick={this.parseCard}
									loading={loading}
									color={invalidCard || receivedInvalidCard ? 'error' : 'primary'}
								>
									{receivedInvalidCard || invalidCard ? (
										formatMessage(messages.invalidCard)
									) : (
										formatMessage(messages.save)
									)}
							</Button>
							</Form>
						</div>
					)}
					<Button
						className="AddCardModal__action__button"
						color="tertiary"
						loading={false}
						onClick={this.showInput}
						size="medium"
					>
						{formatMessage(messages.add)}
					</Button>
					<Button
						className="AddCardModal__action__button"
						color="primary"
						isLink
						loading={false}
						onClick={this.closeModal}
						size="medium"
					>
						{formatMessage(messages.close)}
					</Button>
				</div>
			</Modal>
		);
	}
}

AddCardModal.propTypes = {
	className: PropTypes.string,
	organizationName: PropTypes.string,
	isOpened: PropTypes.bool,
	invalidCard: PropTypes.bool,
	intl: PropTypes.object.isRequired,
	mainCard: PropTypes.string,
	cards: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		card: PropTypes.string
	})),
	onClose: PropTypes.func,
	onRemoveCard: PropTypes.func,
	onAddCard: PropTypes.func
};

AddCardModal.defaultProps = {
	className: '',
	organizationName: '',
	mainCard: '',
	isOpened: false,
	invalidCard: false,
	cards: [],
	onClose: () => {},
	onRemoveCard: () => {},
	onAddCard: () => {}
};

export default injectIntl(AddCardModal);
