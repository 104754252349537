import {
	FETCH_ORDER_BEGIN,
	FETCH_ORDER_SUCCESS,
	FETCH_ORDER_FAILURE,
	ADD_CARD_BEGIN,
	ADD_CARD_SUCCESS,
	ADD_CARD_FAILURE,
	REMOVE_CARD_BEGIN,
	REMOVE_CARD_SUCCESS,
	REMOVE_CARD_FAILURE,
	ADD_MEDIA_BEGIN,
	ADD_MEDIA_SUCCESS,
	ADD_MEDIA_FAILURE,
	REMOVE_ORDER_MEDIA_BEGIN,
	REMOVE_ORDER_MEDIA_SUCCESS,
	REMOVE_ORDER_MEDIA_FAILURE,
	ADD_ALL_DIGITAL_BEGIN,
	ADD_ALL_DIGITAL_SUCCESS,
	ADD_ALL_DIGITAL_FAILURE,
	REMOVE_ALL_DIGITAL_BEGIN,
	REMOVE_ALL_DIGITAL_SUCCESS,
	REMOVE_ALL_DIGITAL_FAILURE,
	SAVE_ORDER_CUSTOMER_BEGIN,
	SAVE_ORDER_CUSTOMER_SUCCESS,
	SAVE_ORDER_CUSTOMER_FAILURE,
	FINISH_BEGIN,
	FINISH_SUCCESS,
	FINISH_FAILURE,
	CHANGE_SELECTED_MEDIA_ORDER_SUCCESS,
	CLEAR_ORDER
} from '../actions';

const initialState = {
	selectedMediaId: null,
	order: {},
	loading: false,
	error: '',
	isAddingAllDigital: false,
	mediaBeingAdded: [],
	mediaBeingRemoved: [],
};

export default function orderReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_ORDER_BEGIN:
	case ADD_CARD_BEGIN:
	case REMOVE_CARD_BEGIN:
	case SAVE_ORDER_CUSTOMER_BEGIN:
	case FINISH_BEGIN:
		return {
			...state,
			loading: true,
			error: '',
		};

	case ADD_MEDIA_BEGIN:
		return {
			...state,
			loading: true,
			error: '',
			mediaBeingAdded: [...state.mediaBeingAdded, action.payload.mediaId],
		};

	case REMOVE_ORDER_MEDIA_BEGIN:
		return {
			...state,
			loading: true,
			error: '',
			mediaBeingRemoved: [...state.mediaBeingRemoved, action.payload.mediaId],
		};

	case REMOVE_ALL_DIGITAL_BEGIN:
	case ADD_ALL_DIGITAL_BEGIN:
		return {
			...state,
			loading: true,
			error: '',
			isAddingAllDigital: true,
		};

	case FETCH_ORDER_SUCCESS:
	case ADD_CARD_SUCCESS:
	case REMOVE_CARD_SUCCESS:
	case ADD_ALL_DIGITAL_SUCCESS:
	case REMOVE_ALL_DIGITAL_SUCCESS:
	case SAVE_ORDER_CUSTOMER_SUCCESS:
	case FINISH_SUCCESS:
		return {
			...state,
			loading: false,
			order: action.payload.order,
			isAddingAllDigital: false,
		};

	case ADD_MEDIA_SUCCESS:
		return {
			...state,
			loading: false,
			order: action.payload.order,
			isAddingAllDigital: false,
			mediaBeingAdded: state.mediaBeingAdded.filter(mediaId => mediaId !== action.payload.mediaId),
		};

	case REMOVE_ORDER_MEDIA_SUCCESS:
		return {
			...state,
			loading: false,
			order: action.payload.order,
			selectedMediaId: null,
			mediaBeingRemoved: state.mediaBeingRemoved.filter(mediaId => mediaId !== action.payload.mediaId),
		};

	case FETCH_ORDER_FAILURE:
	case ADD_CARD_FAILURE:
	case REMOVE_CARD_FAILURE:
	case ADD_ALL_DIGITAL_FAILURE:
	case REMOVE_ALL_DIGITAL_FAILURE:
	case SAVE_ORDER_CUSTOMER_FAILURE:
	case FINISH_FAILURE:
		return {
			...state,
			loading: false,
			error: action.payload.error,
			isAddingAllDigital: false,
		};

	case ADD_MEDIA_FAILURE:
		return {
			...state,
			loading: false,
			error: action.payload.error,
			isAddingAllDigital: false,
			mediaBeingAdded: state.mediaBeingAdded.filter(mediaId => mediaId !== action.payload.mediaId),
		};

	case REMOVE_ORDER_MEDIA_FAILURE:
		return {
			...state,
			loading: false,
			error: action.payload.error,
			isAddingAllDigital: false,
			mediaBeingRemoved: state.mediaBeingRemoved.filter(mediaId => mediaId !== action.payload.mediaId)
		};

	case CHANGE_SELECTED_MEDIA_ORDER_SUCCESS:
		return {
			...state,
			selectedMediaId: action.payload
		}

	case CLEAR_ORDER:
		return {...initialState}

	default:
		return state;
	}
}
