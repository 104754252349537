import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

import './index.sass';

const Input = ({
	id,
	className,
	type,
	autoComplete,
	name,
	label,
	value,
	required,
	disabled,
	error,
	maxLength,
	min,
	max,
	onChange,
	onFocus,
	onBlur
}) => {
	const inputProps = {};

	if (maxLength > -1) inputProps.maxlength = maxLength;
	if (min > -1) inputProps.min = min;
	if (max > -1) inputProps.max = max;

	return (
		<TextField
			id={id}
			type={type}
			name={name}
			autoComplete={autoComplete}
			className={`Input ${className}`}
			label={label}
			value={value}
			required={required}
			disabled={disabled}
			error={error}
			inputProps={inputProps}
			onChange={onChange}
			onFocus={onFocus}
			onBlur={onBlur}
		/>
	)
};

Input.propTypes = {
	id: PropTypes.string,
	type: PropTypes.oneOf(['text', 'number', 'password', 'email', 'document', 'phone']),
	name: PropTypes.string,
	className: PropTypes.string,
	autoComplete: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	error: PropTypes.bool,
	maxLength: PropTypes.number,
	min: PropTypes.number,
	max: PropTypes.number,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func
};

Input.defaultProps = {
	className: '',
	id: '',
	type: 'text',
	name: '',
	autoComplete: '',
	label: 'Input',
	required: false,
	disabled: false,
	error: false,
	maxLength: -1,
	min: -1,
	max: -1,
	onChange: () => {},
	onFocus: () => {},
	onBlur: () => {}
};

export default Input;
