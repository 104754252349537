import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import {fetchOrganization} from '../../store/actions';
import {General, Analytics} from '../../shared';
import HomePage from '../Pages/HomePage';
import GalleryPage from '../Pages/GalleryPage';
import TermsPage from '../Pages/TermsPage';
import PrivacyPolicyPage from '../Pages/PrivacyPolicyPage';
import LoadingPage from '../Pages/LoadingPage';
import ErrorPage from '../Pages/ErrorPage';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.webName = General.getWebNameFromCurrentLocation();
		this.sentFirsOrgPageView = false;
	}

	componentDidUpdate(prevProps) {
		const {location, organization: orgState} = this.props;

		if (location.pathname !== prevProps.location.pathname) {
			this.sendPageView();
		}

		const { organization, loading, error } = orgState;
		const { id, name } = organization;

		if (!loading && !error && id && name && !this.sentFirsOrgPageView) {
			this.sentFirsOrgPageView = true;
			this.sendPageView();
		}
	}

	componentDidMount() {
		this.getOrganizationInfo();
		this.sendPageView();
	}

	sendPageView = () => {
		const {location, organization} = this.props;

		Analytics.sendPageViewFromRouter(location, organization.organization.name);
	}

	getOrganizationInfo() {
		const {dispatch} = this.props;

		dispatch(fetchOrganization(this.webName));
	}

	render() {
		const { organization: organizationState } = this.props;
		const { organization = {}, error } = organizationState;
		const { id } = organization || {};

		if (id) {
			return (
				<Switch>
					<Route exact path="/" component={HomePage} key="roverpix-route-home" />,
					<Route path="/gallery" component={GalleryPage} key="roverpix-route-gallery" />
					<Route path="/terms" component={TermsPage} key="roverpix-route-terms" />
					<Route path="/privacy-policy" component={PrivacyPolicyPage} key="roverpix-route-privacy" />
					<Redirect to="/"/>
				</Switch>
			);
		} else if (error) {
			return <ErrorPage />
		} else {
			return <LoadingPage/>
		}
	}
}

App.propTypes = {
	dispatch: PropTypes.func.isRequired,
	organization: PropTypes.shape({
		organization: PropTypes.shape({
			name: PropTypes.string,
			web_name: PropTypes.string,
			url: PropTypes.string,
			contact_email: PropTypes.string
		}),
		loading: PropTypes.bool,
		error: PropTypes.string
	}).isRequired
};

const mapStateToProps = state => ({
	photos: state.photos,
	organization: state.organization
});

export default withRouter(connect(mapStateToProps)(App));
