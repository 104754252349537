import React from 'react';
import PropTypes from 'prop-types';
import ImgSvg from '../../Atoms/ImgSvg';

import './index.sass';

const LanguageChooser = ({className, onChange}) => (
	<div className={`LanguageChooser ${className}`}>
		<ImgSvg
			className="LanguageChooser__icon"
			name="br"
			onClick={() => onChange('pt')}
		/>
		<ImgSvg
			className="LanguageChooser__icon"
			name="en"
			onClick={() => onChange('en')}
		/>
		<ImgSvg
			className="LanguageChooser__icon"
			name="es"
			onClick={() => onChange('es')}
		/>
	</div>
);

LanguageChooser.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func
};

LanguageChooser.defaultProps = {
	className: '',
	onChange: () => {}
};

export default LanguageChooser;
