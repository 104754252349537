import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const IconButton = ({ className, children, rounded, onClick }) => {
	return (
		<div
			className={`IconButton ${className} ${rounded ? 'IconButton--rounded' : ''}`}
			onKeyUp={() => { }}
			onClick={onClick}
			role="button"
		>
			{children}
		</div>
	)
};

IconButton.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func
};

IconButton.defaultProps = {
	className: '',
	onClick: () => { }
};

export default IconButton;