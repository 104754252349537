/* global fetch */

export const SEARCH_MEDIAS_FROM_CARD = 'SEARCH_MEDIAS_FROM_CARD';
export const searchMediasFromCard = card => ({
	type: SEARCH_MEDIAS_FROM_CARD,
	card
});

export const INVALIDATE_MEDIAS_SEARCH = 'INVALIDATE_MEDIAS_SEARCH';
export const invalidateMediasSearch = card => ({
	type: INVALIDATE_MEDIAS_SEARCH,
	card
});

export const FETCH_MEDIAS_BEGIN = 'FETCH_MEDIAS_BEGIN';
export const fetchMediasBegin = () => ({
	type: FETCH_MEDIAS_BEGIN
});

export const FETCH_MEDIAS_SUCCESS = 'FETCH_MEDIAS_SUCCESS';
export const fetchMediasSuccess = ({ sold_medias, not_sold_medias, extras }) => ({
	type: FETCH_MEDIAS_SUCCESS,
	payload: {
		soldMedias: sold_medias,
		notSoldMedias: not_sold_medias,
		extras: extras
	}
});

export const FETCH_MEDIAS_FAILURE = 'FETCH_MEDIAS_FAILURE';
export const fetchMediasFailure = error => ({
	type: FETCH_MEDIAS_FAILURE,
	payload: { error }
});

const parseCards = (cards) => (typeof cards === 'string' ? [cards] : cards);

export function fetchMedias(cards, organizationId, secret) {
	const cardsList = parseCards(cards)
	let URL = `${process.env.ROVERPIX_API}/api/v1/medias/${organizationId}/${cardsList[0]}`;
	if (secret !== '') {
		URL = URL + `?secret=${secret}`;
	}
	var requestOptions = {
		method: "POST",
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({"cards" : cardsList}),
	};

	return (dispatch) => {
		dispatch(fetchMediasBegin());
		return fetch(URL, requestOptions)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					if (json.message === 'CUSTOMER_CODE_NOT_FOUND' || json.message === 'CARD_NOT_FROM_CUSTOMER') {
						dispatch(fetchMediasFailure('CUSTOMER_CODE_NOT_FOUND'));
					}
					else if (json.message === 'CARD_EMPTY_MEDIA') {
						dispatch(fetchMediasFailure('CARD_EMPTY_MEDIA'));
					}
					dispatch(fetchMediasFailure(json.message));
				} else {
					dispatch(fetchMediasSuccess(json));
				}
			}).catch((e) => {
				dispatch(fetchMediasFailure(e.toString()));
			});
	};
}

export const SELECT_MEDIA_SUCCESS = 'SELECT_MEDIA_SUCCESS';
export const selectMediaSuccess = mediaId => ({
	type: SELECT_MEDIA_SUCCESS,
	payload: mediaId
});

export function selectMedia(mediaId) {
	return (dispatch) => {
		dispatch(selectMediaSuccess(mediaId));
	}
};

export const UNSELECT_MEDIA_SUCCESS = 'UNSELECT_MEDIA_SUCCESS';
export const unselectMediaSuccess = mediaId => ({
	type: UNSELECT_MEDIA_SUCCESS,
	payload: mediaId
});

export function unselectMedia(mediaId) {
	return (dispatch) => {
		dispatch(unselectMediaSuccess(mediaId));
	}
};

export const GENERATE_ZIP_BEGIN = 'GENERATE_ZIP_BEGIN';
export const generateZipBegin = () => ({
	type: GENERATE_ZIP_BEGIN
});

export const GENERATE_ZIP_SUCCESS = 'GENERATE_ZIP_SUCCESS';
export const generateZipSuccess = zipUrl => ({
	type: GENERATE_ZIP_SUCCESS,
	payload: zipUrl
});

export const GENERATE_ZIP_FAILURE = 'GENERATE_ZIP_FAILURE';
export const generateZipFailure = error => ({
	type: GENERATE_ZIP_FAILURE,
	payload: error
});

export function generateZip(mediaIds, orgId) {
	return (dispatch) => {
		dispatch(generateZipBegin());
		const request = new Request(
			`${process.env.ROVERPIX_API}/api/v1/medias/zip?organization_id=${orgId}`,
			{
				method: 'post',
				headers: {'Content-type': 'application/json'},
				body: JSON.stringify({'media_ids': mediaIds})
			}
		);
		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(generateZipFailure(json.error));
				} else {
					dispatch(generateZipSuccess(json.url));
				}
			}).catch((e) => {
				dispatch(generateZipFailure(e));
			});
	};
}

export const CLEAR_MEDIAS = 'CLEAR_MEDIAS';
export const clearMedias = () => ({
	type: CLEAR_MEDIAS,
});

export const REMOVE_MEDIA_BEGIN = 'REMOVE_MEDIA_BEGIN';
export const removeMediaBegin = () => ({
	type: REMOVE_MEDIA_BEGIN
});

export const REMOVE_MEDIA_SUCCESS = 'REMOVE_MEDIA_SUCCESS';
export const removeMediaSuccess = ({ media }) => ({
	type: REMOVE_MEDIA_SUCCESS,
	payload: media
});

export const REMOVE_MEDIA_FAILURE = 'REMOVE_MEDIA_FAILURE';
export const removeMediaFailure = error => ({
	type: REMOVE_MEDIA_FAILURE,
	payload: error
});

export function removeMedia(mediaId) {
	return (dispatch) => {
		dispatch(removeMediaBegin());
		const request = new Request(
			`${process.env.ROVERPIX_API}/api/v1/media/${mediaId}`,
			{
				method: 'delete'
			}
		);
		return fetch(request)
			.then(res => res.json())
			.then((json) => {
				if (json.error) {
					dispatch(removeMediaFailure(json.error));
				} else {
					dispatch(removeMediaSuccess(json));
				}
			}).catch((e) => {
				dispatch(removeMediaFailure(e.toString()));
			});
	};
}
