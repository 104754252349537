import React from 'react';
import MainTemplate from '../../Templates/MainTemplate';
import Loader from '../../Atoms/Loader';

import './index.sass';

const loadingPage = props => (
	<MainTemplate>
		<Loader className="LoadingPage__loader" />
	</MainTemplate>
);

export default loadingPage;