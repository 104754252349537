import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, injectIntl} from 'react-intl';

import './index.sass';

import Modal from '../../Atoms/Modal';
import Button from '../../Atoms/Button';
import Text from '../../Atoms/Text';
import ImgSvg from '../../Atoms/ImgSvg';



const AddPhotoSuccessModal = ({ className, isOpened, intl, cardCode, onClose }) => {
	const {formatMessage} = intl;
	const messages = defineMessages({
		title: {
			id: 'AddPhotoSuccessModal.title',
			defaultMessage: 'Fotos enviadas com sucesso!'
		},
		message: {
			id: 'AddPhotoSuccessModal.message',
			defaultMessage: 'Salve o código abaixo para poder acessar suas fotos:'
		},
		close: {
			id: 'AddPhotoSuccessModal.close',
			defaultMessage: 'Fechar'
		}
	});

	return (
		<Modal
			className={`AddPhotoSuccessModal ${className}`}
			isOpened={isOpened}
			onClose={onClose}
		>
			<div className="AddPhotoSuccessModal__title">
				<Text className="AddPhotoSuccessModal__title__text" variant="caption">
					{formatMessage(messages.title)}
				</Text>
			</div>
			<div className="AddPhotoSuccessModal__message">
				<Text className="AddPhotoSuccessModal__message__text" variant="caption">
					{formatMessage(messages.message)}
				</Text>
			</div>
			<div className="AddPhotoSuccessModal__card">
				<Text className="AddPhotoSuccessModal__card__text" variant="subtitle1">
					{cardCode}
				</Text>
			</div>
			<ImgSvg className="AddPhotoSuccessModal__success" name="success" />
			<div className="AddPhotoSuccessModal__action">
				<Button
					className="AddPhotoSuccessModal__action__button"
					color="primary"
					isLink
					loading={false}
					onClick={onClose}
					size="medium"
				>
					{formatMessage(messages.close)}
				</Button>
			</div>
		</Modal>
	);
}

AddPhotoSuccessModal.propTypes = {
	className: PropTypes.string,
	cardCode: PropTypes.string,
	isOpened: PropTypes.bool,
	intl: PropTypes.object.isRequired,
	onClose: PropTypes.func
};

AddPhotoSuccessModal.defaultProps = {
	className: '',
	cardCode: '',
	isOpened: false,
	onClose: () => {}
}

export default injectIntl(AddPhotoSuccessModal);
