import isURL from 'validator/lib/isURL';
import isEmail from 'validator/lib/isEmail';

class Validator {
	static isURL(value, options = {}) {
		return isURL(value, options);
	}

	static isEmail(value) {
		return isEmail(value);
	}

	static isValidCPF (cpf) {
		if (typeof cpf !== "string") return false
		cpf = cpf.replace(/[\s.-]*/igm, '')
		if (cpf.length !== 11 || !Array.from(cpf).filter(e => e !== cpf[0]).length) {
			return false
		}
		let soma = 0
		let resto
		for (let i = 1; i <= 9; i++)
			soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
		resto = (soma * 10) % 11
		if ((resto === 10) || (resto === 11))  resto = 0
		if (resto !== parseInt(cpf.substring(9, 10)) ) return false
		soma = 0
		for (let i = 1; i <= 10; i++)
			soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
		resto = (soma * 10) % 11
		if ((resto === 10) || (resto === 11))  resto = 0
		if (resto !== parseInt(cpf.substring(10, 11) ) ) return false
		return true
	}
}

export default Validator;
