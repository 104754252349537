import React, { useState } from 'react';

import Text from '../../Atoms/Text';
import Link from '../../Atoms/Link';
import Button from '../../Atoms/Button';

import './index.sass';

const AcceptPrivacy = () => {
	const [accepted, setAccepted] = useState(localStorage.getItem('roverpix-policy-accepted') || false)
	if (accepted) return null

	return (
		<div className="AccpetPrivacy">
			<Text variant="body2">
				Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a nossa <Link href="/privacy-policy" target="_blank">Política de Privacidade</Link> e, ao continuar navegando, você concorda com estas condições.
			</Text>
			<Button
				onClick={() => {
					localStorage.setItem('roverpix-policy-accepted', true)
					setAccepted(localStorage.getItem('roverpix-policy-accepted') || false)
				}}>
				Aceitar
			</Button>
		</div>
	)
}

export default AcceptPrivacy;
