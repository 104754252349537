import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	addMediaToOrder,
	removeMediaFromOrder,
	fetchMedias,
	generateZip,
	changeSelectedOrderMedia,
	removeMedia,
} from "../../../store/actions";
import { LocalStorage, Order, Analytics } from "../../../shared";

import "./index.sass";

import Box from "../../Molecules/Box";
import MessageGallery from "../../Molecules/MessageGallery";
import Text from "../../Atoms/Text";
import Button from "../../Atoms/Button";
import Photo from "../../Atoms/Photo";
import Check from "../../Atoms/Check";
import MediaModal from "../MediaModal";
import Loader from "../../Atoms/Loader";
import Banner from "../../Atoms/Banner";
import LanguageChooser from "../../Molecules/LanguageChooser";

class PhotoGallery extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedMedia: null,
			isSelectedPhotoExtra: false,
			lastSelectedMediaId: null,
			hasBeenVisible: false,
		};

		this.handleNext = this.handleNext.bind(this);
		this.handleBefore = this.handleBefore.bind(this);
		this.getSelectedMedia = this.getSelectedMedia.bind(this);
		this.checkSelect = this.checkSelect.bind(this);
	}

	componentDidMount() {
		this.checkToLoadPhotos();
	}

	componentDidUpdate(prevProps) {
		const { canBuy } = this.props;
		const { lastSelectedMediaId } = this.state;
		const { order: orderState } = prevProps;
		const { selectedMediaId } = orderState;

		if (lastSelectedMediaId !== selectedMediaId) {
			this.setState({
				lastSelectedMediaId: selectedMediaId,
			});
		}

		if (canBuy) {
			this.checkIfOrderChange(prevProps);
		} else {
			this.checkToLoadPhotos();
		}
	}

	checkIfOrderChange = (prevProps) => {
		const { order: oldOrderState } = prevProps;
		const { order: oldOrder } = oldOrderState;
		const { id: olderOrderId } = oldOrder;
		const { order: newOrderState } = this.props;
		const { order: newOrder } = newOrderState;
		const { id: newOrderId } = newOrder;

		if (olderOrderId && olderOrderId !== newOrderId) {
			this.fetchOrderCardPhotos();
		} else {
			this.checkToLoadPhotos();
		}
	};

	checkToLoadPhotos = () => {
		const { media, organization: organizationState } = this.props;
		const { soldMedias, notSoldMedias, loading } = media;
		const { organization = {} } = organizationState || {};
		const { id } = organization || {};

		if (
			(!soldMedias || soldMedias.length === 0) &&
			(!notSoldMedias || notSoldMedias.length === 0) &&
			!loading &&
			!media.error &&
			id
		) {
			this.loadPhotos();
		}
	};

	loadPhotos = () => {
		const {
			cardCode,
			fetchMedias,
			organization: organizationState,
		} = this.props;
		const { organization = {} } = organizationState || {};
		const { id } = organization || {};

		fetchMedias(cardCode, id);
	};

	fetchOrderCardPhotos = () => {
		const {
			order: orderState,
			fetchMedias,
			organization: organizationState,
		} = this.props;
		const { order } = orderState;
		const { card_code } = order;
		const { organization = {} } = organizationState || {};
		const { id } = organization || {};
		if (card_code && id) fetchMedias(card_code, id);
	};

	getSelectedMedia() {
		const { selectedMedia } = this.state;
		const { media } = this.props;
		const { soldMedias, notSoldMedias, extras } = media;
		let mediaToShow = null;

		if (selectedMedia) {
			mediaToShow = soldMedias.find((p) => p.id === selectedMedia);
			if (mediaToShow) {
				mediaToShow.canDownload = true;
				return mediaToShow;
			}

			if (!mediaToShow) {
				mediaToShow = notSoldMedias.find((p) => p.id === selectedMedia);
				if (mediaToShow) {
					mediaToShow.canDownload = false;
					return mediaToShow;
				}
			}

			if (!mediaToShow) {
				mediaToShow = extras.find((e) => e.id === selectedMedia);
				if (mediaToShow) {
					mediaToShow.canDownload = true;
					mediaToShow.type = "photo";
					return mediaToShow;
				}
			}
		}

		return mediaToShow;
	}

	handleNext() {
		this.changeMedia(true);
	}

	handleBefore() {
		this.changeMedia(false);
	}

	changeMedia(next = true) {
		const { media, canBuy, organization: orgState } = this.props;
		const { soldMedias = [], notSoldMedias = [], extras = [] } = media;
		const { organization = {} } = orgState;
		const { features = {} } = organization;
		const { site = {} } = features || {};
		const { free_photos: freePhotos = false } = site || {};

		let medias = [];
		if (canBuy || freePhotos) {
			medias = [...soldMedias, ...notSoldMedias, ...extras];
		} else {
			medias = [...soldMedias, ...extras];
		}
		const selectedMedia = this.getSelectedMedia();

		if (!selectedMedia) return;

		const index = medias.indexOf(selectedMedia);
		const firstPhoto = medias[0];
		const lastPhoto = medias[medias.length - 1];
		const newPhoto = next ? medias[index + 1] : medias[index - 1];

		if (newPhoto) {
			this.setState({
				selectedMedia: newPhoto.id,
			});
		} else {
			this.setState({
				selectedMedia: next ? firstPhoto.id : lastPhoto.id,
			});
		}
	}

	checkSelect(e, media, selected) {
		e.stopPropagation();
		const {
			order: orderState,
			addMediaToOrder,
			removeMediaFromOrder,
			changeSelectedOrderMedia,
		} = this.props;
		const { order } = orderState;
		const { id: orderId } = order;
		const { id: orderItemId } = Order.getDinamicOrderItem(order, media.type);

		if (!selected) {
			addMediaToOrder(orderId, orderItemId, media.id, media.type);
			changeSelectedOrderMedia(media.id);
		} else {
			const associatedItemId = Order.getAssociatedItemIdFromMediaId(
				order,
				media.id
			);

			removeMediaFromOrder(orderId, orderItemId, associatedItemId);
			changeSelectedOrderMedia(null);
		}
	}

	handleRemoveMedia = (mediaId) => {
		const { removeMedia } = this.props;

		this.setState({
			selectedMedia: null,
		});
		removeMedia(mediaId);
	};

	handleAddToOrder = async (e, media) => {
		e.stopPropagation();
		const { order: orderState, organization: orgState } = this.props;
		const { order } = orderState;
		const { organization = {} } = orgState;
		const { name: orgName } = organization;

		this.checkSelect(e, media, Order.isMediaOnOrder(media.id, order));
		Analytics.sendEvent("add_photo_to_cart", "buying", orgName);
	};

	handleTooltipClose = () => {
		const { hasBeenVisible } = this.state;
		if (!hasBeenVisible) {
			this.setState({hasBeenVisible: true});
		}
	};

	render() {
		const {
			media,
			order: orderState,
			organization: orgState,
			intl,
			generateZip,
			canBuy,
		} = this.props;
		const { order, selectedMediaId, loading: orderLoading, mediaBeingAdded } = orderState;
		const { organization = {} } = orgState;
		const {
			id: orgId,
			name: orgName,
			features = {},
			features_organization = {},
		} = organization;
		const { site = {} } = features || {};
		const {
			free_photos: freePhotos = false,
			can_remove_photo: canRemovePhoto = false,
		} = site || {};
		const { config = {} } = features_organization || {};
		const { SITE_BANNER = {} } = config || {};
		const { URL: bannerUrl, HEIGHT: bannerHeight } = SITE_BANNER;
		const allDigitalIsSelected = Order.hasAllDigital(order);
		const { formatMessage } = intl;
		const messages = defineMessages({
			help: {
				id: "gallery.photos.help",
				defaultMessage:
					"Clique nas fotos para ampliar, fazer download, compartilhar ou ",
			},
			downloadZip: {
				id: "gallery.photos.downloadZip",
				defaultMessage: "clique aqui para baixar um ZIP.",
			},
			buyPhoto: {
				id: "gallery.photos.buyPhoto",
				defaultMessage:
					"Clique na foto para ampliá-la e clique no botão para adicioná-la ao carrinho.",
			},
			photosBought: {
				id: "gallery.photos.photosBought",
				defaultMessage:
					"{soldMedias, plural, one {Você adquiriu 1 foto. Muito Obrigado.} other {Você adquiriu # fotos. Muito Obrigado.}}",
			},
			photosToBuy: {
				id: "gallery.photos.photosToBuy",
				defaultMessage:
					"{notSoldMedias, plural, one {# foto disponível para compra no seu cartão.} other {# fotos disponíveis para compra no seu cartão.}}",
			},
			extras: {
				id: "gallery.photos.extras",
				defaultMessage:
					"Conteúdo extra disponível. Clique nas fotos para ampliar, fazer download ou compartilhar.",
			},
		});
		const { soldMedias, notSoldMedias, extras, zip, loading, error } = media;
		const { loading: zipLoading } = zip;
		const { selectedMedia, isSelectedPhotoExtra } =
			this.state;
		const getSelectedPreview = () => {
			const selectedMedia = this.getSelectedMedia();

			if (selectedMedia) {
				return selectedMedia;
			}

			return {};
		};

		if (error === "CARD_EMPTY_PHOTO") {
			return (
				<MessageGallery
					type="empty"
					onTryAgain={() => window.location.reload()}
				/>
			);
		} else if (error) {
			return (
				<MessageGallery
					type="error"
					onTryAgain={() => window.location.reload()}
				/>
			);
		}

		if (
			!canBuy &&
			soldMedias &&
			soldMedias.length === 0 &&
			!loading &&
			!freePhotos
		) {
			return <MessageGallery type="notSoldMedias" />;
		}

		let mainMedias = soldMedias;
		if (freePhotos) {
			mainMedias = [];

			if (notSoldMedias) mainMedias = [...mainMedias, ...notSoldMedias];
			if (soldMedias) mainMedias = [...mainMedias, ...soldMedias];
		}

		const handleChangeLanguage = (value) => {
			const currentValue = LocalStorage.getItem("locale");

			if (currentValue !== value) {
				Analytics.sendEvent(`change_language_to_${value}`, "browsing");
				LocalStorage.setItem("locale", value);
				document.location.reload(true);
			}
		};

		return (
			<div className="PhotoGallery">
				{bannerUrl && (
					<Banner url={bannerUrl} height={bannerHeight || "80px"} />
				)}
				{loading ? (
					<div className="PhotoGallery__loaderContainer">
						<Loader className="PhotoGallery__loaderContainer__loader" />
					</div>
				) : (!soldMedias || soldMedias.length === 0) &&
					(!notSoldMedias || notSoldMedias.length === 0) ? (
					<MessageGallery type="empty" onTryAgain={this.loadPhotos} />
				) : (
					<>
						{(soldMedias.length > 0 || freePhotos) && (
							<>
								{!freePhotos && (
									<Text className="Gallery__help" align="center">
										{formatMessage(messages.photosBought, {
											soldMedias: soldMedias.length,
										})}
									</Text>
								)}
								<Text
									className={`Gallery__help ${
										!freePhotos ? "Gallery--noPadding" : ""
									}`}
									align="center"
								>
									{formatMessage(messages.help)}
									<Button
										className="PhotoGallery__downloadZip"
										isLink
										onClick={() => {
											generateZip(
												mainMedias.map((p) => p.id),
												orgId
											);
											Analytics.sendEvent(
												"download_zip_photos",
												"browsing",
												orgName
											);
										}}
										loading={zipLoading}
									>
										{formatMessage(messages.downloadZip)}
									</Button>
								</Text>

								<Box className="PhotoGallery__wrapper" borderPosition="none">
									<ul className="PhotoGallery__list">
										{mainMedias.map((media) => (
											<li
												className="PhotoGallery__item"
												key={media.id}
												onClick={() => {
													this.setState({
														selectedMedia: media.id,
														isSelectedPhotoExtra: false,
													});
													Analytics.sendEvent(
														"sold_photo_view",
														"browsing",
														orgName
													);
												}}
											>
												<Photo
													id={media.id}
													filename={media.thumb_filename}
													className="PhotoGallery__item__photo"
													type="thumb"
													mediaType={media.type}
													avoidCache={true}
												/>
												<div className="PhotoGallery__item__check__container" />
											</li>
										))}
									</ul>
								</Box>
							</>
						)}

						{canBuy && !freePhotos ? (
							notSoldMedias.length > 0 ? (
								<>
									<Text className="Gallery__help" align="center">
										{formatMessage(messages.photosToBuy, {
											notSoldMedias: notSoldMedias.length,
										})}
									</Text>
									<Text
										className="Gallery__help Gallery--noPadding"
										align="center"
									>
										{formatMessage(messages.buyPhoto)}
									</Text>
									<Box className="PhotoGallery__wrapper" borderPosition="none">
										<ul className="PhotoGallery__list">
											{notSoldMedias.map((media) => (
												<li
													className={`PhotoGallery__item ${
														media.id === selectedMediaId
															? "PhotoGallery__item--selected"
															: null
													}`}
													key={media.id}
													onClick={() => {
														this.setState({
															selectedMedia: media.id,
															isSelectedPhotoExtra: false,
														});
														Analytics.sendEvent(
															"not_sold_photo_view",
															"browsing",
															orgName
														);
													}}
												>
													<Photo
														id={media.id}
														filename={media.thumb_filename}
														className="PhotoGallery__item__photo"
														type="thumb"
														mediaType={media.type}
													/>
													<div className={`PhotoGallery__item__check__container ${allDigitalIsSelected ? "PhotoGallery__item__check__container__disabled": null}`}>
													{orderLoading && mediaBeingAdded.includes(media.id) ? (
															<Loader
																className="PhotoGallery__item__check__loader"
																size="small"
															/>
														) : (
															<Check
																className="PhotoGallery__item__check"
																active={Order.isMediaOnOrder(media.id, order)}
																onClick={(e) => this.handleAddToOrder(e, media)}
															/>
														)}
													</div>
												</li>
											))}
										</ul>
									</Box>
								</>
							) : null
						) : null}

						{extras && extras.length > 0 && (
							<>
								<Text className="Gallery__help" align="center">
									{formatMessage(messages.extras)}
								</Text>
								<Box className="PhotoGallery__wrapper" borderPosition="none">
									<ul className="PhotoGallery__list">
										{extras.map((extra) => (
											<li
												className="PhotoGallery__item"
												key={extra.id}
												onClick={() => {
													this.setState({
														selectedMedia: extra.id,
														isSelectedPhotoExtra: true,
													});
													Analytics.sendEvent(
														"extra_view",
														"browsing",
														orgName
													);
												}}
											>
												<Photo
													id={extra.id}
													filename={extra.filename}
													className="PhotoGallery__item__photo"
													isExtra
												/>
											</li>
										))}
									</ul>
								</Box>
							</>
						)}
					</>
				)}
				<MediaModal
					isOpened={selectedMedia !== null}
					media={getSelectedPreview()}
					order={order}
					onAddToOrder={this.handleAddToOrder}
					canDownloadMedia={freePhotos}
					canRemoveMedia={canRemovePhoto}
					isExtra={isSelectedPhotoExtra}
					onClose={() => this.setState({ selectedMedia: null })}
					onNext={() => {
						this.handleNext();
						Analytics.sendEvent("show_next_photo", "browsing", orgName);
					}}
					onBefore={() => {
						this.handleBefore();
						Analytics.sendEvent("show_previous_photo", "browsing", orgName);
					}}
					onDownloadMedia={() => {
						Analytics.sendEvent("download_photo", "browsing", orgName);
					}}
					onRemoveMedia={this.handleRemoveMedia}
					hasBeenVisible={this.state.hasBeenVisible}
					handleTooltipClose={this.handleTooltipClose}
				/>
				<LanguageChooser
					className="PhotoGallery__languages"
					onChange={handleChangeLanguage}
				/>
			</div>
		);
	}
}

PhotoGallery.propTypes = {
	photos: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			thumb_path: PropTypes.string.isRequired,
			preview_path: PropTypes.string.isRequired,
			original_path: PropTypes.string.isRequired,
			selected: PropTypes.bool,
		})
	),
	isSelectable: PropTypes.bool,
	onDownloadPhoto: PropTypes.func,
	intl: PropTypes.object.isRequired,
	addMediaToOrder: PropTypes.func.isRequired,
	removeMediaFromOrder: PropTypes.func.isRequired,
};

PhotoGallery.defaultProps = {
	photos: [],
	isSelectable: false,
	onDownloadPhoto: () => {},
};

const mapStateToProps = (state) => ({
	media: state.media,
	order: state.order,
	organization: state.organization,
	card: state.card,
	products: state.products,
});

const mapActionsToProps = (dispatch) => ({
	fetchMedias: (cardCode, organizationId) =>
		dispatch(fetchMedias(cardCode, organizationId)),
	addMediaToOrder: (orderId, orderItemId, mediaId, mediaType) =>
		dispatch(addMediaToOrder(orderId, orderItemId, mediaId, mediaType)),
	removeMediaFromOrder: (orderId, orderItemId, associatedItemId) =>
		dispatch(removeMediaFromOrder(orderId, orderItemId, associatedItemId)),
	generateZip: (mediaIds, orgId) => dispatch(generateZip(mediaIds, orgId)),
	changeSelectedOrderMedia: (mediaId) =>
		dispatch(changeSelectedOrderMedia(mediaId)),
	removeMedia: (mediaId) => dispatch(removeMedia(mediaId)),
});

export default connect(
	mapStateToProps,
	mapActionsToProps
)(injectIntl(PhotoGallery));
