import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';

import { orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";

import './index.sass';

const SwitchButton = ({ className, onChange, checked }) => {
	const OrangeSwitch = withStyles({
		switchBase: {
			color: orange[700],
			"&$checked": {
				color: orange[700],
			},
			"&$checked + $track": {
				backgroundColor: orange[800],
			},
		},
		checked: {},
		track: {},
	})(Switch);

	return (
		<OrangeSwitch
			className={`SwitchButton ${className}`}
			checked={checked}
			onChange={onChange}
		/>
	);
}

SwitchButton.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
};

SwitchButton.defaultProps = {
	className: "",
	onChange: () => { },
};

export default SwitchButton;
