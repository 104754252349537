import React, { useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Form from '../../Atoms/Form';
import Input from '../../Atoms/Input';
import Button from '../../Atoms/Button';

import './index.sass';

const GetCustomerForm = ({ className, fields, cpfAsPassword, loading, hasError, buttonMessage, onSubmit, intl }) => {
	const [values, setValues] = useState({})
	const { formatMessage } = intl;
	const messages = defineMessages({
		name: {
			id: 'GetCustomerForm.name',
			defaultMessage: 'Nome'
		},
		lastname: {
			id: 'GetCustomerForm.lastname',
			defaultMessage: 'Sobrenome'
		},
		external_id: {
			id: 'GetCustomerForm.externalId',
			defaultMessage: 'Identificador'
		},
		cpf: {
			id: 'GetCustomerForm.cpf',
			defaultMessage: 'CPF'
		},
		password: {
			id: 'GetCustomerForm.password',
			defaultMessage: 'Senha'
		}
	});

	return (
		<Form
			className={`GetCustomerForm ${className}`}
			onSubmit={(e) => {
				e.preventDefault()
				onSubmit(values)
			}}
		>
			{fields.map((field) => {
				const fieldName = field === 'cpf' && cpfAsPassword ? 'password' : field;

				return (
					<Input
						id={fieldName}
						required
						className="GetCustomerForm__input"
						type={field === 'cpf' && cpfAsPassword ? 'password' : 'text'}
						label={formatMessage(messages[fieldName])}
						name={fieldName}
						value={values[field] || ''}
						onChange={(e) => {
							const lastValues = {...values}
							lastValues[field] = e.target.value
							setValues(lastValues)
						}}
					/>
				)
			})}
			<Button
				type="submit"
				loading={loading}
				color={hasError ? 'error' : 'primary'}
			>
				{buttonMessage}
			</Button>
		</Form>
	)
}

GetCustomerForm.propTypes = {
	className: PropTypes.string,
	cardCode: PropTypes.string,
	buttonMessage: PropTypes.string,
	loading: PropTypes.bool,
	hasError: PropTypes.bool,
	cpfAsPassword: PropTypes.bool,
	fields: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func,
	onSubmit: PropTypes.func
};

GetCustomerForm.defaultProps = {
	className: '',
	cardCode: '',
	buttonMessage: '',
	loading: false,
	hasError: false,
	cpfAsPassword: false,
	fields: [],
	onChange: () => {},
	onSubmit: () => {}
};

export default injectIntl(GetCustomerForm);
