import {combineReducers} from 'redux';
import card from './card';
import order from './order';
import organization from './organization';
import payment from './payment';
import media from './media';
import products from './products';

export default combineReducers({
	card, media, products, organization, order, payment
});
