import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Logo from '../../Atoms/Logo';

const OrganizationLogo = ({ webName }) => (
	<Logo name={webName} />
);

OrganizationLogo.propTypes = {
	webName: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	webName: state.organization.organization && state.organization.organization.web_name
		? state.organization.organization.web_name
		: ''
});

export default connect(mapStateToProps)(OrganizationLogo);
