import React from 'react';
import PropTypes from 'prop-types';

import './index.sass';

const Form = ({ className, id, children, onSubmit }) => (
	<form className={`Form ${className}`} id={id} onSubmit={onSubmit}>
		{children}
	</form>
);

Form.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	onSubmit: PropTypes.func
};

Form.defaultProps = {
	className: '',
	children: null,
	onSubmit: () => {}
};

export default Form;
