import React from 'react';

import MainTemplate from '../../Templates/MainTemplate';
import Helmet from '../../Organisms/Helmet';
import OrganizationLogo from '../../Organisms/OrganizationLogo';
import Text from '../../Atoms/Text';
import Link from '../../Atoms/Link';

import './index.sass';

const PrivacyPolicePage = () => (
	<MainTemplate>
		<Helmet />
		<div className="PrivacyPolicePage">
			<div className="PrivacyPolicePage__header">
				<OrganizationLogo />
				<Text variant="h4">Política de privacidade</Text>
			</div>
			<div className="PrivacyPolicePage__content">
				<Text variant="h5">Introdução</Text>
				<Text variant="body1">Seja bem-vindo à Política de Privacidade da Roverpix!</Text>
				<Text variant="body1">Nós, da Roverpix, prezamos pela segurança dos seus dados pessoais, pelo respeito à sua privacidade e pela transparência em relação aos nossos procedimentos. Em razão disso, elaboramos a presente Política de Privacidade para esclarecer como os seus dados pessoais serão tratados por nós e quais são as medidas que aplicamos para mantê-los seguros.</Text>
				<Text variant="body1">É essencial destacar, também, que o tratamento dos seus dados pessoais pela Roverpix, o qual é descrito nesta Política de Privacidade, é realizado em conformidade com os princípios e diretrizes da Lei Geral de Proteção de Dados (Lei nº 13.709/2018, do Brasil), do Regulamento Geral de Proteção de Dados (Regulamento nº 679/2016, da União Europeia) e das demais legislações, regulamentos e normativas aplicáveis. As práticas de tratamento serão sempre atualizadas, revistas e aprimoradas para se manter em conformidade com a mencionada disciplina legal.</Text>

				<Text variant="h5">Objetivos</Text>
				<Text variant="body1">O objetivo da presente Política de Privacidade é informar ao Usuário, de forma clara e transparente, como ocorre o tratamento dos seus dados pessoais pela Roverpix. Aqui, você verá:</Text>
				<ul style={{listStyle: 'decimal'}}>
					<li><Text variant="body1">Como e quais são os dados pessoais coletados e tratados pela Roverpix;</Text></li>
					<li><Text variant="body1">Com qual finalidade são tratados os dados pessoais do Usuário e quais são os terceiros com os quais são compartilhados;</Text></li>
					<li><Text variant="body1">Por quanto tempo dados pessoais do Usuário são armazenados e quais as ferramentas utilizadas para proteger a sua privacidade;</Text></li>
					<li><Text variant="body1">Quais são os seus direitos como titular de dados pessoais e como o Usuário pode exercê-los.</Text></li>
				</ul>
				<Text variant="body1">Sendo assim, declaramos, por meio desta Política de Privacidade, o nosso compromisso em garantir a segurança e a transparência no tratamento dos seus dados pessoais, de modo a fazer com que a sua experiência ao adquirir os produtos Roverpix seja a mais completa possível.</Text>

				<Text variant="h5">Definições</Text>
				<Text variant="body1">Com o intuito de facilitar a compreensão de certos termos que serão utilizados ao longo desta Política de Privacidade, dispomos, abaixo, alguns conceitos importantes:</Text>
				<ul>
					<li><Text variant="body1">Roverpix Tecnologia Ltda: pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 19.716.840/0001-83  e com sede na Rua Califórnia, n° 1118, Casa 1126, Cidade Monções, CEP: 04566-062, nesta cidade de São Paulo-SP.</Text></li>
					<li><Text variant="body1">Usuário: pode ser um cliente, que adquire algum dos produtos da Roverpix, ou um visitante, que apenas acessou o Site, sem efetuar alguma compra;</Text></li>
					<li><Text variant="body1">Site: sítio eletrônico da Roverpix (roverpix.com);</Text></li>
					<li><Text variant="body1">Dado pessoal: qualquer informação relacionada a uma pessoa natural identificada ou identificável, como nome, endereço de e-mail, CPF, entre outros;</Text></li>
					<li><Text variant="body1">Tratamento de dados pessoais: toda e qualquer operação realizada com os dados pessoais, como a sua coleta, utilização, compartilhamento, armazenamento, eliminação, entre outros;</Text></li>
					<li><Text variant="body1">Controlador: aquele a quem compete as decisões referentes ao tratamento dos dados pessoais. Para os dados tratados com abrigo na presente Política de Privacidade, a Roverpix é a sua Controladora; e</Text></li>
					<li><Text variant="body1">Operador: aquele que efetua o tratamento dos dados pessoais em nome do controlador;</Text></li>
					<li><Text variant="body1">Encarregado: pessoa que serve como canal de comunicação entre o Controlador, o Usuário e a Autoridade Nacional de Proteção de Dados (ANPD), atribuído, ainda, da responsabilidade por executar as seguintes atividades: aceitar reclamações e comunicações dos titulares, prestar esclarecimentos e adotar providências; receber comunicações da autoridade nacional e adotar providências; orientar os funcionários e os contratados da entidade a respeito das práticas a serem tomadas em relação à proteção de dados pessoais; e realizar as demais atribuições determinadas pela Roverpix. Para ocupar o cargo de Encarregado, nomeamos Robson Conceição.</Text></li>
				</ul>
				<Text variant="body1">Para garantir uma maior leveza e fluidez à leitura do presente documento, podemos utilizar, como sinônimos do termo “Roverpix”, as palavras “nós”, “nossos”, “somos”, dentre outros. Também poderemos utilizar, como sinônimo do termo “Usuário”, as palavras “você”, “seu”, “sua”, dentre outros.</Text>

				<Text variant="h5">Como os seus dados pessoais são coletados</Text>
				<Text variant="body1">A Roverpix coleta os dados pessoais de seus Usuários das seguintes formas:</Text>
				<ul>
					<li>
						<Text variant="body1">Quando o Usuário realiza a compra de algum produto: ao realizar uma compra em nosso Site, você deverá fornecer o(s) seu(s):</Text>
						<ul>
							<li><Text variant="body1">Nome, sobrenome e endereço de e-mail, Documento (CPF, Passaporte, outro), Telefone, Endereço (rua, número da casa/prédio, bairro, município, estado, país e CEP);</Text></li>
							<li><Text variant="body1">Dados do cartão de crédito (nome do titular do cartão, número do cartão, código de segurança e data de validade do cartão), caso essa tenha sido a forma de pagamento escolhida.</Text></li>
						</ul>
					</li>
				</ul>

				<Text variant="h5">Quais são os dados pessoais tratados pela Roverpix</Text>
				<Text variant="body1">A Roverpix pode tratar os seguintes dados pessoais:</Text>
				<ul>
					<li><Text variant="body1">Dados de pagamento por cartão de crédito: nome do titular do cartão, número do cartão, código de segurança e data de validade do cartão.</Text></li>
				</ul>
				<Text variant="body1">Acerca dos dados de pagamento, é importante ressaltar que não armazenamos os seus dados do cartão de crédito em nosso Site. Tais dados são inseridos e compartilhados com  o “Pagarme”, nosso fornecedor de transações de crédito, para proceder e efetivar a operação de crédito junto às operadoras de cartão de crédito aceitas no Site.</Text>

				<Text variant="h5">De que forma a Roverpix realiza o tratamento dos seus dados pessoais</Text>
				<Text variant="body1">A Roverpix realiza o tratamento dos dados pessoais de seus Usuários para as seguintes finalidades:</Text>
				<ul>
					<li><Text variant="body1">Realizar transações financeiras: podemos tratar os seus dados pessoais caso você opte pelo pagamento por cartão de crédito. Nessa oportunidade, iremos repassar os seus dados à empresa responsável pelas transações de crédito;</Text></li>
					<li><Text variant="body1">Cumprir com obrigações fiscais: podemos utilizar os seus dados pessoais para cumprir com nossas obrigações fiscais, como, por exemplo, para emitir a nota fiscal referente à sua compra;</Text></li>
					<li><Text variant="body1">Enviar newsletters, promoções e descontos: caso o Usuário manifeste interesse, poderemos utilizar os seus dados pessoais para enviá-lo oportunidades exclusivas e novidades sobre os produtos da Roverpix.</Text></li>
				</ul>

				<Text variant="h5">Com quem a Roverpix compartilha os seus dados pessoais</Text>
				<Text variant="body1">Compartilhamos os dados do usuário em situações bem específicas com os nossos operadores terceiros para a viabilização de transações financeiras. O compartilhamento visa exclusivamente viabilizar a prestação do serviço de compra dos produtos adquiridos pelo Usuário, ou seja, garantir que a sua compra ocorra com sucesso e a melhor experiência possível.</Text>

				<Text variant="h5">Por quanto tempo os seus dados pessoais são armazenados</Text>
				<Text variant="body1">A Roverpix armazena os dados pessoais do usuário até que eles não sejam mais necessários para as finalidades aqui descritas e até o fim do período de tratamento.</Text>
				<Text variant="body1">Após isso, os dados pessoais serão eliminados, podendo ser conservados para as seguintes finalidades:</Text>
				<ul>
					<li><Text variant="body1">Cumprimento de obrigação legal ou regulatória pelo controlador;</Text></li>
					<li><Text variant="body1">Uso exclusivo do controlador, desde que o seu acesso seja proibido por terceiros e os dados sejam anonimizados.</Text></li>
				</ul>
				<Text variant="body1">Ainda, os dados pessoais tratados com base no consentimento do Usuário poderão ser eliminados, tendo fundamento o direito de revogação do consentimento previsto em lei, desde que sejam observadas as exceções acima.</Text>

				<Text variant="h5">Quais as ferramentas de segurança utilizadas para proteger os seus dados pessoais</Text>
				<Text variant="body1">A proteção dos seus dados pessoais é peça chave para nós, tendo em vista que a vemos como um elemento essencial para construirmos uma relação de confiança. Dessa forma, armazenamos os seus dados pessoais de forma segura em serviços de armazenamento em nuvem, utilizando mecanismos e políticas internas para prevenir acessos não autorizados, destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.</Text>
				<Text variant="body1">Muito embora nossas medidas de segurança sejam eficazes, deixamos claro que nenhuma operação envolvendo dados pela internet é 100% segura. Sendo assim, nós, na Roverpix, não medimos esforços para garantir o máximo nível de proteção aos seus dados pessoais e estamos dispostos, sempre que necessário, a auxiliá-lo por meio de nossos canais de comunicação. Para manter nossas medidas de segurança atualizadas, levamos em conta os seguintes fatores: o modo pelo qual o tratamento é realizado; o resultado e os riscos que razoavelmente dele se esperam; e as técnicas de tratamento de dados pessoais disponíveis à época em que ele foi realizado.</Text>
				<Text variant="body1">Caso ocorra algum incidente de segurança envolvendo seus dados, nós comunicaremos o ocorrido a você e à Autoridade Nacional de Proteção de Dados em tempo razoável, bem como informaremos: a descrição da natureza dos dados pessoais afetados; as medidas técnicas e de segurança utilizadas para a proteção dos dados, observados os segredos comercial e industrial; os riscos relacionados ao incidente; e as medidas que foram ou que serão adotadas para reverter ou mitigar os efeitos do prejuízo.</Text>

				<Text variant="h5">Responsabilidades do Controlador e Operador</Text>
				<Text variant="body1">De acordo com a Lei Geral de Proteção de Dados, o Controlador ou o Operador que, em razão do exercício de atividade de tratamento de dados pessoais, causar a outrem dano, em violação à legislação de proteção de dados pessoais, poderá ser obrigado a repará-lo. Para evitar isso, adotamos as melhores práticas em relação aos seus dados pessoais, de modo a prevenir possíveis falhas e práticas em desconformidade com a lei.</Text>
				<Text variant="body1">Caso o dano, no entanto, tenha sido predominantemente causado direta ou indiretamente por alguma conduta praticada por terceiro ou pelo próprio Usuário, seja tal conduta comissiva ou omissiva, ou não tenha havido tratamento irregular de dados, nem violação à legislação aplicável, a Roverpix não será responsável pelo pagamento de qualquer indenização.</Text>

				<Text variant="h5">Direitos do Usuário</Text>
				<Text variant="body1">Nós, da Roverpix, colocamos o bem-estar do Usuário e a garantia de seus direitos como um compromisso majoritário. Nesse sentido, você poderá, a qualquer momento, por meio de requerimento gratuito, obter do Controlador, mediante envio de mensagem ao endereço de e-mail indicado no item “Tire suas dúvidas/Entre em contato” abaixo:</Text>
				<ul>
					<li><Text variant="body1">Confirmação da existência de tratamento;</Text></li>
					<li><Text variant="body1">Acesso aos dados;</Text></li>
					<li><Text variant="body1">Correção de dados incompletos, inexatos ou desatualizados;</Text></li>
					<li><Text variant="body1">Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a legislação correspondente e a esta Política de Privacidade;</Text></li>
					<li><Text variant="body1">Portabilidade dos dados a outro fornecedor de serviço ou produto;</Text></li>
					<li><Text variant="body1">Eliminação dos dados pessoais tratados com o consentimento do usuário;</Text></li>
					<li><Text variant="body1">Informação das entidades privadas com as quais o controlador realizou uso compartilhado de dados;</Text></li>
					<li><Text variant="body1">Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</Text></li>
					<li><Text variant="body1">Revogação do consentimento mediante manifestação expressa do usuário, por procedimento fácil e gratuito.</Text></li>
				</ul>

				<Text variant="h5">Alterações à Política de Privacidade</Text>
				<Text variant="body1">Possuímos o direito de alterar a presente Política de Privacidade e/ou adaptá-la à legislação em vigor, independente de justificativa e de prévia notificação ao Usuário.</Text>
				<Text variant="body1">Nesse caso, iremos comunicá-lo sobre a mudança através de um e-mail e/ou de uma comunicação em nosso Site. Em ambos os casos, ao continuar utilizando o nosso Site e não comunicar a sua insatisfação com as alterações realizadas, o Usuário demonstra a sua concordância com as novas normas.</Text>

				<Text variant="h5">Tire suas dúvidas/Entre em contato</Text>
				<Text variant="body1">Em caso de dúvidas acerca da forma como utilizamos os seus dados pessoais, sobre como exercer os seus direitos ou sobre a nossa Política de Privacidade, contate o nosso Encarregado por meio do seguinte e-mail:</Text>
				<Text variant="body1"><Link href="contato@roverpix.com"/></Text>
				<Text variant="body1">Para a solução de eventuais controvérsias decorrentes desta Política de Privacidade, serão aplicadas as disposições previstas na Lei Geral de Proteção de Dados (LGPD), e no que couber, a Regulação Geral de Proteção de Dados (LGPD).</Text>
				<br/>
				<Text variant="caption">Atualizado em 29 de Abril de 2021.</Text>
			</div>
		</div>
	</MainTemplate>
)

export default PrivacyPolicePage;