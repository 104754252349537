import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, injectIntl} from 'react-intl';
import { connect } from 'react-redux';

import './index.sass';

import Modal from '../../Atoms/Modal';
import Button from '../../Atoms/Button';
import Text from '../../Atoms/Text';
import PriceList from '../../Molecules/PriceList';

class ProductModal extends React.Component {
	render() {
		const { className, isOpened, onClose, intl, products = []} = this.props;
		const {formatMessage} = intl;
		const messages = defineMessages({
			help: {
				id: 'gallery.header.help',
				defaultMessage: 'Valores'
			},
			button: {
				id: 'productModal.button',
				defaultMessage: 'Entendi'
			},
			additionalMedia: {
				id: 'productModal.additionalMedia',
				defaultMessage: 'Item adicional'
			},
			additionalPhoto: {
				id: 'productModal.additionalPhoto',
				defaultMessage: 'Foto adicional'
			},
			additionalVideo: {
				id: 'productModal.additionalVideo',
				defaultMessage: 'Video adicional'
			},
			photosTitle: {
				id: "productModal.photosTitle",
				defaultMessage: "Fotos"
			},
			videosTitle: {
				id: "productModal.videosTitle",
				defaultMessage: "Videos"
			},
			photosVideosTitle: {
				id: "productModal.photosVideosTitle",
				defaultMessage: "Fotos + Videos"
			},
			allDigitalValue: {
				id: "productModal.allDigitalValue",
				defaultMessage: "Todas as digitais"
			},
		});
		const { products: onlineProducts, all_digital } = products
		const getAllDigitalPrice = () => {
			if (all_digital) {
				return all_digital.price
			}
			return null
		}
		const allDigitalPrice = getAllDigitalPrice()
		const getRanges = (product) => {
			const { ranges_price = [] } = product
			const getLastRangeMinPhotoCount = () => {
				const lastRangeIndex = ranges_price.length - 1;
				const lastRange = ranges_price[lastRangeIndex];

				return lastRange.min_photos;
			};
			const rangeSize = getLastRangeMinPhotoCount() + 1
			const ranges = [];
			const getRangeInfo = (index) => {
				let range = ranges_price.find((r) => (r.min_photo <= index || r.max_photos >= index))
				if (!range) range = ranges_price[ranges_price.length - 1];

				let price = 0
				let name = ''
				if (index >= rangeSize) {
					price = range.price
					name = product.is_video && product.is_photo? formatMessage(messages.additionalMedia)
						: product.is_photo ? formatMessage(messages.additionalPhoto) : formatMessage(messages.additionalVideo)
				} else {
					price = ranges.length > 0 ? ranges[ranges.length - 1].price : 0
					price = price + range.price
					name = index.toString()
				}

				return {name: name, price: price, id: range.id}
			}
			for (let i=1; i<=rangeSize; i++) {
				ranges.push(getRangeInfo(i))
				if (allDigitalPrice > 0 && i === rangeSize) {
					ranges.push({name: formatMessage(messages.allDigitalValue), price: allDigitalPrice, id: 'all_digital'})
				}
			}
			return ranges
		}

		return (
			<Modal
				className={`ProductModal ${className}`}
				isOpened={isOpened}
				onClose={onClose}
			>
				<div className="ProductModal__title">
					<Text className="ProductModal__title__text" variant="caption">
						{formatMessage(messages.help)}
					</Text>
				</div>
				<div className="ProductModal__tables">
					{
						onlineProducts.map(product => (
							<div className="ProductModal__table" key={product.id}>
								<Text className="ProductModal__title__text" variant="caption">
									{product.is_video && product.is_photo? formatMessage(messages.photosVideosTitle)
										: product.is_photo ? formatMessage(messages.photosTitle) : formatMessage(messages.videosTitle)
									}
								</Text>
								{getRanges(product).map(({id, name, price}, index) => (
									<PriceList
										productName={name}
										productPrice={price}
										key={`${id}-${index}`}
									/>
								))}
							</div>
						))
					}
				</div>
				<div className="ProductModal__action">
					<Button
						className="ProductModal__action__button"
						color="primary"
						isLink
						loading={false}
						onClick={onClose}
						size="medium"
					>
						{formatMessage(messages.button)}
					</Button>
				</div>
			</Modal>
		);
	}
}

ProductModal.propTypes = {
	className: PropTypes.string,
	isOpened: PropTypes.bool,
	intl: PropTypes.object.isRequired,
	onClose: PropTypes.func,
	products: PropTypes.shape({
		products: PropTypes.arrayOf(PropTypes.shape({
			price: PropTypes.number
		}))
	})
};

ProductModal.defaultProps = {
	className: '',
	isOpened: false,
	products: {},
	onClose: () => {},
};

const mapStateToProps = state => ({
	products: state.products
});

export default connect(mapStateToProps, null)(injectIntl(ProductModal));
